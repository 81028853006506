import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import { Link as ReactLink } from "react-router-dom";
import React, { useContext } from "react";
import { FooterWrapper } from "./style";
import { ReactComponent as EmailIcon } from "../../../assets/icons/email.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/phone.svg";
import { ReactComponent as MapIcon } from "../../../assets/icons/map.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/icons/instagram.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook.svg";
import { ReactComponent as TiktokIcon } from "../../../assets/icons/tiktok.svg";
import { ReactComponent as YoutubeIcon } from "../../../assets/icons/youtube.svg";

import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";

const Footer: React.FC = () => {
  const isMd = useMediaQuery("(min-width:600px)");
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const goToPath = (path: string) => {
    window.open(path, "_blank");
  };

  return (
    <FooterWrapper component={"footer"}>
      <Box
        className="footer-details"
        sx={{
          color: "#fff",
          fontSize: "10px",
          ...(isMd && { flexDirection: "row !important", maxWidth: "90%" }),
        }}
      >
        <Typography
          sx={{ color: "#fff", fontSize: "10px", whiteSpace: "nowrap" }}
        >
          © {new Date().getFullYear()} Dubai Sports World. All rights reserved.
        </Typography>
        <Box
          className="links"
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
            ...(isMd && { width: "auto", marginLeft: "auto" }),
          }}
        >
          <Link
            component={ReactLink}
            to={`${company?.footerPagesLinks?.refund}`}
            target="_blank"
            sx={{ textDecoration: "none", color: "#fff", fontSize: "10px" }}
            onClick={(e) => {
              if (!company?.footerPagesLinks?.refund) {
                e.preventDefault();
              }
            }}
          >
            Cancellation Policy
          </Link>

          <Link
            component={ReactLink}
            to={`${company?.footerPagesLinks?.terms}`}
            target="_blank"
            sx={{ textDecoration: "none", color: "#fff", fontSize: "10px" }}
            onClick={(e) => {
              if (!company?.footerPagesLinks?.terms) {
                e.preventDefault();
              }
            }}
          >
            Terms & Conditions
          </Link>

          <Link
            component={ReactLink}
            to={`${company?.footerPagesLinks?.privacy}`}
            target="_blank"
            sx={{ textDecoration: "none", color: "#fff", fontSize: "10px" }}
            onClick={(e) => {
              if (!company?.footerPagesLinks?.privacy) {
                e.preventDefault();
              }
            }}
          >
            Privacy Policy
          </Link>

          <Typography
            onClick={() => {
              if (company?.footerPagesLinks?.aboutUs)
                goToPath(`${company?.footerPagesLinks?.aboutUs}`);
            }}
            sx={{ color: "#fff", fontSize: "10px" }}
          >
            About Us
          </Typography>
        </Box>

        <Box
          className="address"
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
            ...(isMd && {
              marginBottom: "0px !important",
              width: "auto",
              marginLeft: 0,
            }),
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontSize: "10px",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              whiteSpace: "nowrap",
            }}
            component="a"
            target="_blank"
            rel="noreferrer"
            href="https://goo.gl/maps/GBAsuKpxfYrsHf9u9"
          >
            <MapIcon />
            Dubai Sports World
          </Typography>

          <Typography
            sx={{
              color: "#fff",
              fontSize: "10px",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              whiteSpace: "nowrap",
            }}
            component="a"
            href="tel:8003982"
          >
            <PhoneIcon />
            800DWTC (3982)
          </Typography>

          <Typography
            sx={{
              color: "#fff",
              fontSize: "10px",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
            }}
            component="a"
            href="mailto:support@dubaisportsworld.ae"
          >
            <EmailIcon />
            support@dubaisportsworld.ae
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
            ...(isMd && { width: "fit-content" }),
          }}
        >
          <Link
            component={ReactLink}
            to={`https://www.instagram.com/dubaisportsworld/`}
            target="_blank"
            sx={{ textDecoration: "none", color: "#fff", fontSize: "10px" }}
          >
            <InstagramIcon />
          </Link>
          <Link
            component={ReactLink}
            to={`https://www.facebook.com/DubaiSportsWorld`}
            target="_blank"
            sx={{ textDecoration: "none", color: "#fff", fontSize: "10px" }}
          >
            <FacebookIcon />
          </Link>
          <Link
            component={ReactLink}
            to={`https://www.youtube.com/user/DubaiSportsWorld`}
            target="_blank"
            sx={{ textDecoration: "none", color: "#fff", fontSize: "10px" }}
          >
            <YoutubeIcon />
          </Link>
          <Link
            component={ReactLink}
            to={`https://www.tiktok.com/@dubaisportsworld`}
            target="_blank"
            sx={{ textDecoration: "none", color: "#fff", fontSize: "10px" }}
          >
            <TiktokIcon />
          </Link>
        </Box>
      </Box>
    </FooterWrapper>
  );
};

export default Footer;
