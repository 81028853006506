import {
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import PrimaryBtn from "../FormCollection/PrimaryBtn";

import ChildInput from "./ChildInput";
import { IFormUIProps } from "./Types";
import { Link } from "react-router-dom";

const FormUI: React.FC<IFormUIProps> = ({
  onSubmitHandler,
  fields,
  errors,
  register,
}) => {
  return (
    <Stack
      component={"form"}
      sx={{ marginTop: "40px", gap: "24px", width: "100%" }}
      onSubmit={onSubmitHandler}
    >
      {fields.map((field, index) => (
        <ChildInput
          key={field.id}
          index={index}
          field={field}
          errors={errors}
          register={register}
        />
      ))}

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              {...register("checkbox", {
                required: "You must check this field before submit",
              })}
              value={true}
            />
          }
          label=""
          sx={{ fontSize: "14px", color: "#808080", fontweight: 300 }}
        />
        <Link
          style={{ color: "#808080", textAlign: "center" }}
          to="/consent-form"
          target="_blank"
        >
          I agree to the Parental Waiver and Consent Form.
        </Link>
        {errors.checkbox && (
          <Typography sx={{ fontSize: "12px", color: "#C82E34" }}>
            {errors.checkbox.message}
          </Typography>
        )}
      </Box>

      <PrimaryBtn text="Proceed" type="submit" />
    </Stack>
  );
};

export default FormUI;
