import { Typography } from "@mui/material";
import React, { useContext } from "react";
import {
  AvailableStartingTimesResource,
  AvailableStartingTimesSlot,
} from "../../../hooks/useAvailableStartingTimes";
import SlotsList from "./SlotsList";
import { SlotsSectionWrapper } from "./style";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface IProps {
  label: string;
  slots: AvailableStartingTimesSlot[];
  availableStartingTimes: AvailableStartingTimesResource;
  addToCart: any;
  areAllOffPeakSlots: boolean;
}
const SlotsSection: React.FC<IProps> = ({
  label,
  slots,
  addToCart,
  areAllOffPeakSlots
}) => {

  const navigate =useNavigate();

  const {
    selectedSlot,
    setSelectedSlot,
    selectedService,
    servicesMap,
    company,
    selectedDuration,
    quantity,
    selectedResource,
    setSelectedSession
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const handleAddToCart = async () => {
    const service = servicesMap?.get(selectedService);
    if (service?.addons && service.addons.length > 0) {
      if (service?.subType === "kids") {
        navigate(`/KidsBooking?companyId=${company?.id}`);
      } else {
        navigate(`/AddOns?companyId=${company?.id}`);
      }
    } else {
      const addedToCart = await addToCart({
        selectedService,
        selectedDuration,
        selectedSlot,
        quantity: quantity || 1,
        selectedSession: undefined,
        addonsList: [],
        childrenInformation: [],
        selectedResource,
        timezone: company?.timezone
      });
      if (addedToCart) {
        setSelectedSession(undefined);
        setSelectedSlot(undefined);
        navigate(`/CartCheckout?companyId=${company?.id}`);
      } else {
        toast("Error while adding the item to the cart", {
          theme: "colored",
          type: "error",
        });
      }
    }
  };

  return (
    <SlotsSectionWrapper className="slots-section">
      <Typography className="label">{label}</Typography>
      <SlotsList
        slots={slots}
        addToCart={handleAddToCart}
        areAllOffPeakSlots={areAllOffPeakSlots}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        timezone={company?.timezone}
      />
    </SlotsSectionWrapper>
  );
};

export default SlotsSection;
