import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AddonItem from "../../components/cart/AddonsItem";
import ContentLoader from "../../components/shared/ContentLoader";

import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import useAddons from "../../hooks/useAddons";
import useCart from "../../hooks/useCart";

import { AddOnsWrapper } from "./style";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import { toast } from "react-toastify";
import useTitle from "../../hooks/useTitle";

interface IProps {
  title: string;
}
const AddOns: React.FC<any> = ({ title }) => {
  useTitle(title);
  const naviagte = useNavigate();
  const {
    selectedService,
    isCartLoading,
    selectedDuration,
    selectedSlot,
    selectedSession,
    setSelectedSession,
    setSelectedSlot,
    quantity,
    company,
    selectedResource,
    servicesMap,
  } = useContext(CheckoutContext) as CheckoutContextProps;
  const navigate = useNavigate();

  const service = servicesMap?.get(selectedService)

  if (!selectedSlot && !selectedSession) {
    if (service?.type === "class" && selectedService) {
      navigate(`/Class?companyId=${company?.id}&serviceId=${selectedService}`)
    } else if (selectedService) {
      navigate(`/Calendar?companyId=${company?.id}&serviceId=${selectedService}`)
    } else {
      navigate(`/Calendar?companyId=${company?.id}`)
    }
  }

  const { isLoading, addons } = useAddons(selectedService);
  const [searchParams] = useSearchParams();
  const companyId = searchParams.get("companyId");

  const [selectedAddonsQuantities, setSelectedAddonsQuantities] = useState<
    Map<string, number>
  >(new Map<string, number>());

  const { addToCart } = useCart();

  const getAddonsList = () => {
    const addonsList: any = [];
    selectedAddonsQuantities.forEach((value, key) => {
      addonsList.push({ id: key, quantity: value });
    });
    return addonsList;
  };

  const location = useLocation();
  const childrenInformation = location.state?.childrenInformation;

  return (
    <ContentLoader isLoading={isLoading || isCartLoading}>
      <AddOnsWrapper className="equipment-container">
        <Box className="section">
          <Box className="equipment-options">
            <Typography className="equipment-header add-equipment">
              Add On Equipment
            </Typography>
          </Box>
          {/* <div className="skip-button">
            <PrimaryBtn
              text="Skip Add Ons & Proceed to checkout"
              onClick={() => {
                addToCart({
                  selectedService,
                  selectedDuration: selectedDuration!,
                  selectedSlot: selectedSlot!,
                  quantity: 1,
                });
                naviagte("/CartCheckout");
              }}
              sx={{
                height: "32px",
                fontSize: "14px",
                maxWidth: "260px"
              }}
            />
          </div> */}
          <Box className="products-section">
            <Box className="products-section-items items-container">
              {addons.map((addon) => (
                <AddonItem
                  key={addon.id}
                  addon={addon}
                  setSelectedAddonsQuantities={setSelectedAddonsQuantities}
                />
              ))}
            </Box>
          </Box>
          <PrimaryBtn
            text="Proceed to Checkout"
            onClick={async () => {
              const addedToCart = await addToCart({
                selectedService,
                selectedDuration,
                selectedSlot,
                quantity: quantity || 1,
                selectedSession,
                addonsList: getAddonsList(),
                childrenInformation,
                selectedResource,
                timezone: company?.timezone
              });

              if (addedToCart) {
                setSelectedSession(undefined);
                setSelectedSlot(undefined);
                naviagte(`/CartCheckout?companyId=${company?.id}`);
              } else {
                toast("Error while adding the item to the cart", {
                  theme: "colored",
                  type: "error",
                });
              }
            }}
            sx={{ marginTop: "67px", width: "100%" }}
          />
        </Box>
      </AddOnsWrapper>
    </ContentLoader>
  );
};

export default AddOns;
