import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CancellationHeader from "../../components/cancellation/CancelationHeader";
import CancelBookingButton from "../../components/cancellation/CancelBookingButton";
import ConfirmationBookingDetails from "../../components/shared/bookingDetails/ConfirmationBookingDetails";
import useTitle from "../../hooks/useTitle";

import { extractBookingDetails } from "../../Utils/extract";
import { CancellationWrapper } from "./style";
import CartCheckoutDetails from "../../components/cart/CartCheckoutDetails";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";

interface IProps {
  title: string;
}
const Cancellation: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const [searchParams] = useSearchParams();
  const cartId = searchParams.get("cartId");

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;


  return (
    <CancellationWrapper>
      <CancellationHeader />
      {cartId && <CartCheckoutDetails cartId={cartId} isCancelBooking={true}/>}
      <div style={{ width: "100%", marginBottom: "32px" }}>
        <PrimaryBtn
          text="Back To Home"
          onClick={() => {
            window.open(company?.website || "","_self")
          }}
        />
      </div>
    </CancellationWrapper>
  );
};

export default Cancellation;
