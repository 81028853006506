import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const CancellationHeaderWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;

  .cancellation-label {
    font-size: 18px;
    font-style: normal;
    font-weight: normal;
  }

  .cancel-button {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    height: 60px;
    margin-left: 5px;
    border-radius: 8px;
    box-shadow: 0px 6px 40px #d4d9e8;
    text-transform: capitalize;
  }
`;
