import React, { useContext } from "react";
import {
  Box,
  SwipeableDrawer,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import SideBarHeader from "./SidebarHeader";
import SidebarLink from "./SidebarLink";
import { SidebarProps } from "./Type";
import { useAuth } from "../../../context/AuthContext";
import PrimaryBtn from "../../FormCollection/PrimaryBtn";
import { useNavigate } from "react-router-dom";
import { isValidUser } from "../../../Utils/validate";
import { RegistrationStep } from "../../../Utils/constants";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";

const Sidebar: React.FC<SidebarProps> = ({ isOpen, toggleDrawer }) => {
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const handleLogout = async () => {
    await logout();
    navigate(`/?companyId=${company?.id}`);
  };

  const handleWhatsAppHelp = () => {
    window.open(`https://wa.me/${company?.whatsappHelpPhoneNumber}`, "_blank");
  };

  const list = () => (
    <Box
      sx={{
        maxWidth: 350,
        width: "90vw",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "44px 38px",
      }}
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      {isValidUser(user) && (
        <Typography sx={{ textAlign: "center" }}>
          {" "}
          Welcome {user?.firstName}!{" "}
        </Typography>
      )}
      <List
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <SidebarLink name="Home" path={`/?companyId=${company?.id}`} />
        {isValidUser(user) && (
          <SidebarLink
            name="My Profile"
            path={`/my-profile?companyId=${company?.id}`}
          />
        )}
        <SidebarLink
          name="Book a Sport"
          path={`/Calendar?companyId=${company?.id}`}
        />

        <SidebarLink
          name="Buy Dubai Sports World Package"
          path={`/packages?companyId=${company?.id}`}
        />

        <SidebarLink
          name="Play at Dubai Kids World"
          path={`/KidsArea?companyId=${company?.id}`}
        />

        <SidebarLink
          name="Buy Dubai Kids World Membership"
          path={`/memberships?companyId=${company?.id}`}
        />

        <SidebarLink
          name="Celebrate at Dubai Kids World"
          path={`/birthday-celebration?companyId=${company?.id}`}
        />

        <SidebarLink
          name="Attend an Event"
          path={`/fun-activities?companyId=${company?.id}`}
        />
        
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => handleWhatsAppHelp()}
            sx={{ padding: 0 }}
          >
            <ListItemText primary={"Support/Chat with us"} />
          </ListItemButton>
        </ListItem>
      </List>
      {/* <a
        href="https://www.dubaisportsworld.ae/"
        rel="noreferrer"
        target="_blank"
        style={{ color: "#808080", textAlign: "center", marginBottom: "10px" }}
      >
        dubaisportsworld.aeimport WhatsAppIcon from '@mui/icons-material/WhatsApp';

      </a> */}

      {isValidUser(user) ? (
        <PrimaryBtn text="Log Out" onClick={handleLogout} />
      ) : (
        <PrimaryBtn
          text="Login"
          onClick={() =>
            navigate(`/Register?companyId=${company?.id}`, {
              state: { registrationStep: RegistrationStep.Login },
            })
          }
        />
      )}

      {/* <Button
        onClick={handleWhatsAppHelp}
        sx={{
          backgroundColor: "#25D366",
          color: "#fff",
          '&:hover': {
            backgroundColor: "#1DA851",
          },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          margin: "20px 0",
        }}
      >
        <WhatsAppIcon />
        WhatsApp Help
      </Button> */}
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor="left"
      open={isOpen}
      onClose={() => toggleDrawer(false)}
      onOpen={() => toggleDrawer(true)}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          padding: "8px 20px",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <SideBarHeader toggleDrawer={() => toggleDrawer(false)} />
      </Box>
      {list()}
    </SwipeableDrawer>
  );
};

export default Sidebar;
