import React from "react";
import { PackageContainerProps } from "./Types";
import PackageContainerRow from "./PackageContainerRow";
import { Box, Typography, Divider } from "@mui/material";

const PackageContainer: React.FC<PackageContainerProps> = ({
  title,
  prices,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        marginY: "13px",
        paddingX: "12px",
        borderRadius: "12px",
        boxShadow: "0 6px 40px rgba(208, 208, 208, 0.2)",
      }}
    >
      <Typography
        variant="h3"
        component={"h3"}
        sx={{
          color: "#C82E34",
          fontSize: "20px",
          textAlign: "left",
          fontWeight: "700",
        }}
      >
        {title}
      </Typography>
      {prices.map((item) => (
        <>
          {item.id === "price" && (
            <Divider variant="middle" sx={{ width: "50%", marginX: "auto" }} />
          )}
          <PackageContainerRow key={item.label} {...item} />
        </>
      ))}
    </Box>
  );
};

export default PackageContainer;
