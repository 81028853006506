import React, { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DaySelector from "../../components/Calendar/DaySelector";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import useTitle from "../../hooks/useTitle";
import dayjs from "dayjs";
import CustomFooter from "../../components/shared/footer/CustomFooter";
import { CalendarWrapper } from "./style";
import SportSelector from "../../components/MarketplaceCalendar/SportSelector";
import CompanySelector from "../../components/MarketplaceCalendar/CompanySelector";
import useMutliCompaniesAvailability from "../../hooks/useMutliCompaniesAvailability";
import ContentLoader from "../../components/shared/ContentLoader";
import { Typography } from "@mui/material";
import useSportsAndLocations, {
  Sport,
} from "../../hooks/useSportsAndLocations";
import Heading from "../../components/shared/Heading";
import useGTMDataLayer from "../../hooks/useGTMDataLayer";

interface IProps {
  title: string;
}

const MarketplaceCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const [selectedLocationTag, setSelectedLocationTag] =
    React.useState<string>("");
  const [selectedSportsTag, setSelectedSportTag] = React.useState<string>("");

  const {
    company,
    selectedDay,
    setSelectedDay,
    setShouldShowServiceDropdown,
    setIsAllResourcesOptionEnabled,
    marketplaceName,
    setMarketplaceName,
    setBookingCart,
    countdownApi,
    isCartLoading,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const [searchParams] = useSearchParams();
  const serviceId = searchParams.get("serviceId");
  const resourceId = searchParams.get("resourceId");
  const martketplaceName = searchParams.get("marketplaceName");
  const date = searchParams.get("date");

  useEffect(() => {
    if (date) {
      setSelectedDay(dayjs(date, "DD/MM/YYYY").format("DD/MM/YYYY"));
      setSelectedPickerDate(dayjs(date, "DD/MM/YYYY").toDate());
    }
  }, [date]);

  useEffect(() => {
    setShouldShowServiceDropdown(!!serviceId);
    setIsAllResourcesOptionEnabled(resourceId === null);

    setBookingCart([]);
    countdownApi.pause();
    localStorage.removeItem("cart");
  }, []);

  useEffect(() => {
    if (martketplaceName) setMarketplaceName(martketplaceName);
  }, [martketplaceName]);

  const [selectedPickerDate, setSelectedPickerDate] = useState<Date>(
    new Date(dayjs(selectedDay, "DD/MM/YYYY").toISOString())
  );

  const { isLoading: isLoadingSportsAndLocations, sports } =
    useSportsAndLocations(martketplaceName || "");
  const { loading: isLoadingStartingTimes, companiesAvailability } =
    useMutliCompaniesAvailability({
      location: selectedLocationTag,
      sport: selectedSportsTag,
      date: selectedDay,
      marketplaceName: marketplaceName!,
    });

  useGTMDataLayer("view_marketplace_items_list");


  return (
    <ContentLoader isLoading={isLoadingSportsAndLocations || isCartLoading}>
      <CalendarWrapper>
        <SportSelector
          selectedLocationTag={selectedLocationTag}
          setSelectedLocationTag={setSelectedLocationTag}
          selectedSportTag={selectedSportsTag}
          setSelectedSportTag={setSelectedSportTag}
          sports={sports}
        />

        <DaySelector
          selectedPickerDate={selectedPickerDate}
          setSelectedPickerDate={setSelectedPickerDate}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
        />

        <ContentLoader isLoading={isLoadingStartingTimes}>
          <>
            {companiesAvailability &&
            companiesAvailability?.length > 0 ? null : (
              <Typography textAlign={"center"}>
                Sorry, we don't have any availability for the selected day
                please select another day.
              </Typography>
            )}
            {companiesAvailability?.map((companyAvailability) => (
              <CompanySelector
                availableStartingTimes={
                  companyAvailability.availableStartingTimesResource
                }
                slotsMap={companyAvailability.slotsMap}
              />
            ))}
          </>
        </ContentLoader>

        {company?.hasFooter && <CustomFooter />}
      </CalendarWrapper>
    </ContentLoader>
  );
};

export default MarketplaceCalendar;
