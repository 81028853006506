import { List, ListItem, Stack, Typography } from "@mui/material";
import Heading from "../../components/shared/Heading";
import LandingPageTitle from "../../components/shared/LandingPageTitle";

const PivacyPolicy = () => {
  return (
    <Stack sx={{ marginTop: 2, gap: 3 }}>
      <Heading heading="Our Polices" />

      <Stack>
        <Typography>
          Dubai World Trade Center L.L.C. respects your privacy and is committed
          to protecting it. We provide this Privacy Notice to inform you of our
          privacy procedures and practices, including what information we
          collect about you, how we obtain your information and how we use your
          information.
        </Typography>
        <Typography>
          For the purposes of this Privacy Notice "we", "our" and "us" refer to
          Dubai World Trade Center L.L.C., "this website" collectively refers to
          the dwtc.com website, its sub-domains and all other websites owned by
          Dubai World Trade Center and "you" and "your" refer to a specific
          individual accessing these websites, a visitor to any of our events,
          or exhibitors showcasing at our events.
        </Typography>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Data collection and use" />
        <Stack>
          <Typography>
            The information we may collect from you includes:
          </Typography>
          <List>
            <ListItem>
              Information establishing your identity (for example, name,
              address, email address, date of birth, passport or Emirates ID)
            </ListItem>
            <ListItem>
              Financial information (for example, credit card details)
            </ListItem>
            <ListItem>
              Information relating to your use of this website (for example,
              domain name, IP address and cookies)
            </ListItem>
            <ListItem>
              Information relating to your attendance at events at Dubai World
              Trade Center (such as records of attendance, feedback provided and
              attributable to you, images, audio and video captured, security
              data such as video recording)
            </ListItem>
            <ListItem>
              Where you are an exhibitor or business partner, we may collect
              information relating to your staff or agents (such as name,
              contact details, proof of identity)
            </ListItem>
            <ListItem>
              Any information you independently choose to provide to us (for
              example, if you send us an email or call us).
            </ListItem>
          </List>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="How do we obtain your information?" />
        <Stack>
          <Typography>
            We may collect your information during the course of our
            relationship with you and will only use your personal information in
            accordance with applicable data protection law. We may obtain this
            information from a variety of sources, including:
          </Typography>
          <List>
            <ListItem>
              From you (for example, when you access or purchase products or
              services from this website, send emails, attend or exhibit at our
              events, or otherwise provide us with your personal information)
            </ListItem>
            <ListItem>From suppliers</ListItem>
            <ListItem>From advertisers or marketing companies</ListItem>
          </List>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="How we use your information" />
        <Stack>
          <Typography>
            We may use or process your personal information for the following
            purposes
          </Typography>
          <List>
            <ListItem>
              To provide you with information about our products, goods,
              services and events and other information that we think may be
              valuable to you
            </ListItem>
            <ListItem>
              Where you are attending an event (as an exhibitor or a visitor) in
              the course of facilitating the delivery and management of the
              event
            </ListItem>
            <ListItem>
              To personalise our services, products and content to you and your
              geographic region (including but not limited to advertisements and
              promotions)
            </ListItem>
            <ListItem>
              To respond to any queries, requests, or comments that you may have
            </ListItem>
            <ListItem>To process your payments</ListItem>
            <ListItem>
              To review, develop and improve the services which we offer
            </ListItem>
            <ListItem>To comply with legal obligations</ListItem>
          </List>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="On what basis we use your information" />
        <Stack>
          <Typography>
            We use your personal information on the following bases:
          </Typography>
          <List>
            <ListItem>
              For legitimate interests purposes. Using your personal information
              helps us to operate, improve and minimise any disruption to the
              products, goods and services that we may offer to you. We also
              have a legitimate interest in sending you information on the
              events and offerings we believe will be of interest to you
            </ListItem>
            <ListItem>
              Because the information is necessary for the performance of a
              contract with you or to take steps at your request to enter into a
              contract
            </ListItem>
            <ListItem>
              Because you have given your consent (if we expressly ask for
              consent to process your personal information, for a specific
              purpose)
            </ListItem>
            <ListItem>To comply with legal and regulatory obligations</ListItem>
          </List>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="With whom do we share your information?" />
        <Stack>
          <Typography>
            We will only disclose or transfer your personal information for the
            purposes set out in this notice to:
          </Typography>
          <List>
            <ListItem>
              Third party service providers and sub-contractors who provide a
              service to us (including but not limited to marketing and
              advertising, data processing, IT and office services)
            </ListItem>
            <ListItem>
              Any event partners for the purposes of registration and attendance
            </ListItem>
            <ListItem>
              Any competent authorities if we are legally required to comply
              with a request for data.
            </ListItem>
          </List>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Countries to which we transfer your information." />
        <Stack>
          <Typography>
            The information you submit may be transferred, stored and hosted
            outside of the United Arab Emirates or the country from which you
            may be accessing this website, and may be transferred to countries
            which do not have data protection laws or to countries where your
            privacy and other fundamental rights will not be protected as
            extensively. These and all other transmissions will remain secure
            and under our sole control.
          </Typography>
          <Typography>
            We will implement appropriate measures to ensure that your personal
            information remains protected and secure when it is transferred
            outside your home country and you can exercise your rights
            effectively.
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Cookies" />
        <Stack>
          <Typography>
            Cookies are small pieces of information that your browser stores on
            your computer when requested by a website.
          </Typography>
          <Typography>
            We request your browser to store a cookie when you access this
            website and may request your browser to store one or more cookies
            when you make purchases, request or personalise information, or
            register yourself for certain services.
          </Typography>
          <Typography>
            Unless you explicitly opt to store additional information, our
            cookies only contain one or more sequential computer-generated
            identifiers. The cookies that this website requests your browser to
            store are suffixed with "dwtc.com" and expire at the very latest
            after 365 days from the date on which they were last updated. Most
            browsers automatically store cookies by default, but you can choose
            to refuse or selectively accept to store certain cookies by
            adjusting the preferences in your browser. However, if your browser
            refuses to store cookies, some features of this website may not be
            available to you and some pages on this website may not display
            properly. You can find information on popular browsers and how to
            adjust your cookie preferences at the following websites:
          </Typography>
          <List>
            <ListItem>
              Microsoft Internet Explorer: http://www.microsoft.com/...
            </ListItem>
            <ListItem>Mozilla Firefox: http://www.mozilla.org/...</ListItem>
            <ListItem>Google Chrome: www.google.com/chrome</ListItem>
          </List>
          <Typography>
            Additionally, browsers such as Microsoft Internet Explorer feature
            The Platform for Privacy Preferences (P3P) technology, which gives
            you the ability to make informed choices about the use of your
            personal information online. P3P-enabled browsers can be configured
            to automatically accept or refuse to store cookies, based on whether
            a website's P3P compact policy file meets your privacy preferences.
            We will make every effort to provide a P3P compact policy file for
            each cookie that this website requests your browser to store.
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Data retention" />
        <Stack>
          <Typography>
            We will keep your data only for as long as is necessary to respond
            to any queries or complaints, to improve the services that we offer
            to you or to comply with any legal obligations to which we may be
            subject. Once you delete your account all data related to your
            account is also deleted.
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Your rights" />
        <Stack>
          <Typography>Please contact us if you would like to:</Typography>
          <List>
            <ListItem>
              Access your data – request a copy of your personal information
              that we process about you
            </ListItem>
            <ListItem>
              Rectify your data – request us to amend or update your personal
              information where it is inaccurate or incomplete
            </ListItem>
            <ListItem>
              Erase your data – request us to delete your personal information
              where it is no longer necessary for the purpose(s) for which your
              information was originally collected
            </ListItem>
            <ListItem>
              Restrict your data – request us temporarily or permanently to stop
              processing all or some of your personal data
            </ListItem>
            <ListItem>
              Object to the use of your data – at any time, object to us
              processing your personal information where it is based exclusively
              on our legitimate interests (see above) or for direct marketing
              purposes
            </ListItem>
            <ListItem>
              Receive or transmit your data in a machine-readable and structured
              format (otherwise known as "data portability") – request the
              receipt or transmission of your personal information to another
              organisation, in a structured and machine-readable format
            </ListItem>
            <ListItem>
              Object to any automated decision-making – request us not to
              subject you to a decision based solely on automated decision
              making, including profiling, where the decision would have a legal
              effect on you or produce a similarly significant effect
            </ListItem>
            <ListItem>
              Withdraw your consent – withdraw your consent at any time to the
              use of your personal information for a particular purpose (where
              we have asked you for consent to use your information for that
              particular purpose)
            </ListItem>
          </List>
          <Typography>
            For any of the above, please email us at data@dwtc.com or send
            postal mail to the following address:
          </Typography>
          <Typography component={"address"}>
            Dubai World Trade Center L.L.C.
          </Typography>
          <Typography component={"address"}>
            P.O. Box 9292, Dubai, UAE
          </Typography>
          <Typography>
            Subject to any overriding legal obligations, requirements and/or
            exemptions, we will endeavour to respond to your request within (30)
            days of receipt, unless we require further information from you. We
            may ask you to provide proof of your identity.
          </Typography>
          <Typography>
            If you feel that we do not comply with applicable data protection
            and privacy rules, you may lodge a complaint with a competent data
            protection authority.
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Children" />
        <Stack>
          <Typography>
            We are committed to protecting the privacy needs of children and we
            encourage parents and guardians to take an active role in their
            children's online activities and interests. We do not knowingly
            collect information from children and we do not target our websites
            to children.
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Security" />
        <Stack>
          <Typography>
            We are committed to protecting the information you provide us. We
            have implemented security policies, rules and technical measures to
            protect the personal data that we have under our control, in
            accordance with applicable data protection laws. The security
            measures are designed to prevent unauthorised access, improper use
            or disclosure, unauthorised modification and unlawful destruction or
            accidental loss.
          </Typography>
          <Typography>
            We use Secure Sockets Layer (SSL) encryption when collecting or
            transferring sensitive data such as credit card information. SSL
            encryption is designed to make the information unreadable by anyone
            but us. This security measure is working when you see either the
            symbol of an unbroken key or closed lock (depending on your browser)
            on the bottom of your browser window. You should be aware that no
            data transmission over the Internet can be guaranteed as totally
            secure. Although we strive to protect such information, we do not
            guarantee the security of any information that you transmit to us
            over the Internet and you do so at your own risk. If you do not feel
            it is appropriate to send information electronically, please contact
            +971 4 389 3999 for an alternative way of conducting business.
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Privacy compliance" />
        <Stack>
          <Typography>
            We are voluntarily committed to a Self-Assessment procedure. Data
            privacy legislation is subject to revision and we recommend that you
            regularly check our Policies.
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Whistle Blowing" />
        <Stack>
          <Typography>
            Aligned with Dubai World Trade Centre's mission, values and
            commitment to the highest standards of integrity and ethical
            behavior, we endeavour to present clear guidelines related to
            protected disclosure or 'whistle blowing'. Business misconduct
            including incidents of unacceptable or undesirable conduct by our
            officers and employees is not tolerated and is harmful to business
            and society. If you have information concerning malpractice,
            impropriety or alleged wrongful conduct, please utilise the
            following email address inconfidence@dwtc.com to report your
            concerns. Depending on the nature of the concerns, the exact
            timescales for the investigations will be flexible. However, every
            effort will be made to address the issue at the earliest.
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Hyperlinks" />
        <Stack>
          <Typography>
            Our websites may provide links to third-party websites for your
            convenience. If you access those links, you will leave our website.
            We do not control those websites or their privacy practices, which
            may differ from ours. We do not endorse or make any representations
            about third-party websites. This Privacy Notice does not cover the
            personal data you choose to give to unrelated third parties. We
            encourage you to review the privacy notice of any company before
            submitting your personal information. Some third-party companies may
            choose to share their personal data with us; that sharing is
            governed by that third-party company's privacy notice.
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Notification of changes" />
        <Stack>
          <Typography>
            We may be required to update or change our Privacy Notice from time
            to time. We will upload an updated Privacy Notice on this website.
            If there are any significant changes to the Privacy Notice (e.g. if
            we decide to use your personal information in a manner different
            from that stated at the time it was collected), we will notify you
            by way of an email.
          </Typography>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Contacting us" />
        <Stack>
          <Typography>
            This Privacy Notice sets out in broad terms how we handle your
            personal information and safeguard our privacy. If you have any
            questions relating to our Privacy Notice send postal mail to the
            following address:
          </Typography>
          <Typography component={"span"}>The Data Officer,</Typography>
          <Typography component={"address"}>
            Dubai World Trade Center L.L.C.
          </Typography>
          <Typography component={"address"}>
            P.O. Box 9292, Dubai, UAE
          </Typography>
          <Typography component={"span"}>
            Telephone: +971 4 389 3999, Fax: +971-4-3312173
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PivacyPolicy;
