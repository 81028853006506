import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";
import PrimaryBtn from "../FormCollection/PrimaryBtn";
import { ICancelCardModalProps } from "./Type";

const CancelCardModal: React.FC<ICancelCardModalProps> = ({
  btnText,
  isOpen,
  onClick,
  onClose,
  text,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        textAlign: "center",
        background: "transparent",

        "& .MuiDialog-paper": {
          borderRadius: "12px",
          padding: "25px 14px 15px",
          boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <DialogContentText
          sx={{ fontSize: "12px", fontWeight: 500, marginBottom: "28px" }}
        >
          {text}
        </DialogContentText>
        <DialogActions sx={{ padding: 0 }}>
          <PrimaryBtn
            text={btnText}
            sx={{
              fontSize: "10px",
              fontWeight: 500,
              height: "25px",
              width: "70%",
              marginX: "auto",
            }}
            onClick={onClick}
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default CancelCardModal;
