import React, { useContext, useState } from "react";

import DaySelector from "../../components/Calendar/DaySelector";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";

import useTitle from "../../hooks/useTitle";

import ClassSelector from "../../components/Calendar/ClassSelector";

import dayjs from "dayjs";
import { KidsCalendarWrapper } from "./style";
import TimeSelector from "../../components/Calendar/TimeSelector";
import useAvailableStartingTimes from "../../hooks/useAvailableStartingTimes";
import useCart from "../../hooks/useCart";
import QuantitySelector from "../../components/Calendar/QuantitySelector";
interface IProps {
  title: string;
}

const KidsCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const {
    company,
    selectedService,
    selectedDay,
    setSelectedDay,
    selectedLocation,
    selectedResource,
    isAllResourcesOptionEnabled,
    availableStartingTimesResource,
    bookingCart,
    isCartLoading,
    servicesMap,
  } = useContext(CheckoutContext) as CheckoutContextProps;



  
  const { addToCart } = useCart();

  const [selectedPickerDate, setSelectedPickerDate] = useState<Date>(
    new Date(dayjs(selectedDay, "DD/MM/YYYY").toISOString())
  );
  
  const { loading: isLoadingStartingTimes } = useAvailableStartingTimes({
    companyId: company?.id,
    branchId: selectedLocation,
    serviceId: selectedService,
    selectedDay,
    selectedResource,
    isAllResourcesOptionEnabled,
    bookingCart,
    selectedPickerDate
  });

  const service = servicesMap?.get(selectedService);

  return (
    
      <KidsCalendarWrapper>
        {/* <Heading heading="Kids Play Area" /> */}
        <ClassSelector type="standard" subType="kids" />

        <DaySelector
          selectedPickerDate={selectedPickerDate}
          setSelectedPickerDate={setSelectedPickerDate}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
        />



        {service?.maxQuantity && service.maxQuantity > 1 && (
          <QuantitySelector />
        )}

        <TimeSelector
          availableStartingTimes={availableStartingTimesResource!}
          isLoadingStartingTimes={isLoadingStartingTimes || isCartLoading}
          addToCart={addToCart}
          showDurationFilter={true}
          timeUnit="Hour"
          startTime={service?.workingHours?.start}
          endTime={service?.workingHours?.end}
        />
      </KidsCalendarWrapper>
   
  );
};

export default KidsCalendar;
