import React, { useContext, useEffect, useRef, useState } from "react";
import useCart, { AddonPurchase, Booking } from "../../hooks/useCart";
import SessionCard from "../SessionCard";
import ContentLoader from "../shared/ContentLoader";
import { Stack, Typography } from "@mui/material";
import PricingCard from "../shared/PricingCard";
import dayjs from "dayjs";
import useProfile from "../../hooks/useProfile";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import { getCurrency } from "../../Utils/format";
import { DSW_COMPANY_ID } from "../../Utils/constants";
import { addCartItemsEvent } from "../../Utils/gtmEvents";

interface IProps {
  cartId: string;
  isCancelBooking: boolean;
  shouldRedirectToCancel?: boolean;
}
const CartCheckoutDetails: React.FC<IProps> = ({ cartId, isCancelBooking, shouldRedirectToCancel }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [discount, setDiscount] = useState<number>(0);
  const [cart , setCart] = useState<any>();
  const [cancellationConfirmation, setCancellationConfirmation] = useState<string>("");
  const { getCart } = useCart();
  const hasCalledAPI = useRef(false);

  const getCartData = async () => {
    setIsLoading(true);
    const data = await getCart(cartId);

    const { bookings, addonPurchases, cart } = data;


    setCart(cart);
    const updatedBookings = bookings.map((booking: Booking) => {
      const matchingAddons = addonPurchases?.filter(
        (addon: AddonPurchase) => addon.linkedBookingId === booking.id
      );
      return {
        ...booking,
        addonPurchases: matchingAddons?.length > 0 ? matchingAddons : null,
      };
    });

    const total = cart?.newCartTotalBeforeRefunds || cart?.newCartTotal || 0;
    const subTotal = cart?.originalCartTotalBeforeRefunds || cart?.originalCartTotal || 0;
    setTotal(total);
    setSubTotal(subTotal);
    setDiscount((subTotal - total) || 0);
    setBookings(updatedBookings);
    setIsLoading(false);
    if (location.pathname === "/confirmation") {
      setTimeout(
        () =>
          addCartItemsEvent("purchase", updatedBookings, {
            transaction_id: cartId,
            currency: getCurrency(company?.currency),
            value: cart?.newCartTotal,
            quantity: updatedBookings.length,
          }),
        3000
      );
    }
  };

  useEffect(() => {
    if (hasCalledAPI.current) return;
    hasCalledAPI.current = true;
    getCartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { cancelBooking } = useProfile();
  const onCancelBookingHandler = async (id: string, date: string, price: number) => {
    try {
      if (shouldRedirectToCancel) {
        navigate(`/cancellation?companyId=${company?.id}&cartId=${cartId}`);
        return;
      }
      setIsLoading(true);
      await cancelBooking(id);
      setCancellationConfirmation(`Your booking on ${dayjs(date, "YYYYMMDD").format("DD/MM/YYYY")} for ${price} ${getCurrency(company?.currency)} is cancelled and a refund is on the way to original mode of payment, please allow up to 14 days for the refund to be processed.`)
      await getCartData();
    } catch (error: any) {
      toast(error.response.data.message, {
        theme: "colored",
        type: "error",
      });
      setIsLoading(false)
      setCancellationConfirmation("")
    }

  }


  useEffect(() => {
    if (company?.id === DSW_COMPANY_ID && location.pathname === "/confirmation" && !!cart) {

      const customerEmail = cart.customer.email;
      const customerName = cart.customer.name;
      const total = cart?.newCartTotalBeforeRefunds || cart?.newCartTotal || 0;
      const subTotal = cart?.originalCartTotalBeforeRefunds || cart?.originalCartTotal || 0;
      const discount = (subTotal - total) || 0;

      const customDataParams = `&customerEmail=${customerEmail}&customerName=${customerName}&total=${total}&discount=${discount}&cartId=${cartId}`;
      const qualtricsUrl = `https://zn72qbpcertksraah-dwtc.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_72qBPcErtKSRAah${customDataParams}`;

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.text = `
      (function(){var g=function(e,h,f,g){

        this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
        
        this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
        
        this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
        
        this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
        
        this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
        
        try{(new g(100,"r","QSI_S_ZN_72qBPcErtKSRAah","${qualtricsUrl}")).start()}catch(i){}})();
      `;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [company , location.pathname]);

  return (
    <ContentLoader isLoading={isLoading}>
      <>
        <Typography color={"red"} style={{ marginBottom: "15px", textAlign: "center" }}>{cancellationConfirmation}</Typography>
        <Stack sx={{ width: "100%", gap: "32px" }}>
          {bookings.map((booking) => (
            <SessionCard {...booking} isCancelAddon={false} key={booking.id} isCancelBooking={isCancelBooking} onCancelBookingHandler={onCancelBookingHandler} />
          ))}
        </Stack>
        <PricingCard
          subTotal={subTotal}
          total={total}
          discount={discount}
          vat={discount}
          isPaid={true}
        />
      </>
    </ContentLoader>
  );
};

export default CartCheckoutDetails;
