import { useContext, useEffect, useMemo } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import { Box } from "@mui/system";
import Heading from "../../shared/Heading";
import SportsTabs from "../ServiceSelector/Tabs";
import { ServiceSelectorWrapper } from "../ServiceSelector/style";
import { Service } from "../../../hooks/useCompany";

interface IProps {
  type: "class" | "coach" | "standard";
  subType?: "fun" | "gym" | "kids" | "fitness"
  serviceId?: string;
  uponSelectCallback?: any;
}

const shouldConsiderThisService = (service: Service, type: string, subType?: string) => {
  if (!service.type) return false;
  if (service.type && service.type === type && service.subType === subType) return true;
  return false;
}
const ClassSelector: React.FC<IProps> = ({ subType, type, serviceId , uponSelectCallback }) => {
  const { servicesMap, setSelectedService, setSelectedLocation, selectedService } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;


  const servicesOptions = useMemo(() => {
    const options: any[] = [];
    if (!servicesMap || servicesMap.size === 0) return options;

    servicesMap.forEach((service) => {
      if (shouldConsiderThisService(service, type, subType)) {
        options.push({
          value: service.id,
          label: service.name,
        });
      }
    });

    options.sort((a, b) =>
      a.label > b.label ? 1 : a.label < b.label ? -1 : 0
    );

    let newSelectedService = options[0]?.value;

    if (serviceId && servicesMap.has(serviceId)) {
      const service = servicesMap.get(serviceId);
      newSelectedService = service?.id;
    }

    setSelectedService(newSelectedService);
    setSelectedLocation(servicesMap.get(newSelectedService)?.branchId!);

    return options;
  }, [serviceId, servicesMap, type]);

  const onServiceChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: string
  ) => {
    setSelectedService(value);
    if(uponSelectCallback){uponSelectCallback(value)}
  };

  return (
    <ServiceSelectorWrapper>
      <Box className="dropdown-menus sports-selector" sx={{ width: "100%" }}>
        {(type === "class" && subType === "fitness") ? <Heading heading="Attend An Event" sx={{ width: "100%" }} /> : <></>}

        <SportsTabs
          onChange={onServiceChange}
          value={selectedService}
          data={servicesOptions}
        />
      </Box>
    </ServiceSelectorWrapper>
  )
};

export default ClassSelector;