import { Box, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { CartItemWrapper } from "./style";
import { Booking } from "../../hooks/useCart";
import SessionCardDate from "../SessionCard/SessionCardDate";
import SessionRow from "../SessionCard/SessionRow";
import CardAddonRow from "./CardAddonRow";
import { formatStringTime, getCurrency } from "../../Utils/format";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";

interface IProps {
  booking: Booking;
}
const CartItem: React.FC<IProps> = ({ booking }) => {
  const month = booking.date.slice(4, 6);
  const day = booking.date.slice(6);

  const { company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  return (
    <CartItemWrapper className="cartItem">
      <SessionCardDate day={day} month={month} />

      <Box sx={{ paddingY: "15px", paddingLeft: "15px", width: "100%" }}>
        <Stack
          sx={{
            gap: "12px",
            flexWrap: "wrap",
            "@media (min-width: 768px)": {
              flexDirection: "row",
              alignItems: "center",
            },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "left",
              fontSize: "20px",
              fontWeight: 500,
              color: "#33334F",
            }}
          >
            {`${booking.service.serviceName} ${booking.quantity && booking.quantity > 1
              ? `x${booking.quantity}`
              : ""
              }`}
          </Typography>
          <SessionRow
            booking={booking}
            startTime={formatStringTime(booking.startTime)}
            duration={booking.serviceDuration.durationTime}
            price={booking.price * booking.quantity}
            currency={getCurrency(company?.currency)}
          />
        </Stack>

        {!!booking.addonPurchases?.length &&
          booking.addonPurchases.map((addon: any) => (
            <CardAddonRow addon={addon} key={`${booking.id}-${addon.id}`} />
          ))}
      </Box>
    </CartItemWrapper>
  );
};

export default CartItem;
