import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useCompany from "../../hooks/useCompany";

import ContentLoader from "../../components/shared/ContentLoader";
import DaySelector from "../../components/Calendar/DaySelector";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";

import useTitle from "../../hooks/useTitle";

import ClassSessionSelector from "../../components/Calendar/ClassSessionSelector";
import ClassSelector from "../../components/Calendar/ClassSelector";
import useClassSessions from "../../hooks/useClassSessions";
import dayjs from "dayjs";
import { ClassCalendarWrapper } from "./style";
import CustomFooter from "../../components/shared/footer/CustomFooter";

interface IProps {
  title: string;
}

const ClassCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const [searchParams] = useSearchParams();
  const date = searchParams.get("date");
  const serviceId = searchParams.get("serviceId");

  

  const { selectedService, company, selectedDay, setSelectedDay, setQuantity , servicesMap } =
    useContext(CheckoutContext) as CheckoutContextProps;

  const { isLoading: isLoadingSessions, sessions  } = useClassSessions(
    company?.id || "",
    selectedService,
    dayjs(selectedDay, "DD/MM/YYYY").format("YYYYMMDD")
  );

  const [selectedPickerDate, setSelectedPickerDate] = useState<Date>(
    new Date(dayjs(selectedDay, "DD/MM/YYYY").toISOString())
  );

  setQuantity(1);

  const service = servicesMap?.get(selectedService)


  useEffect(()=> {
    if(date){
      setSelectedDay(dayjs(date,"DD/MM/YYYY").format("DD/MM/YYYY"));
      setSelectedPickerDate(dayjs(date,"DD/MM/YYYY").toDate());
    }
  },[date])

  return (
    
      <ClassCalendarWrapper>
        <ClassSelector type="class" serviceId={serviceId || ""} />
        <DaySelector
          selectedPickerDate={selectedPickerDate}
          setSelectedPickerDate={setSelectedPickerDate}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
        />

      
        <ClassSessionSelector
          sessions={sessions}
          isLoading={isLoadingSessions}
          type="fitness"
        />

        {company?.hasFooter && <CustomFooter />}
      </ClassCalendarWrapper>
   
  );
};

export default ClassCalendar;
