import { useContext, useEffect, useState } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../context/CheckoutContext";

import { Booking } from "./useCart";
import axiosInstance from "../services/axiosInstance";
import dayjs from "dayjs";
import { Service } from "./useCompany";
import { Addon } from "./useAddons";

interface AvailableStartingTimesInput {
  companyId?: string | null;
  branchId?: string | null;
  serviceId?: string;
  selectedDay?: string | null;
  selectedResource?: string | null;
  isAllResourcesOptionEnabled: boolean;
  bookingCart: Booking[];
  selectedPickerDate: Date;
}

export interface AvailableStartingTimesSlot {
  fullDate: string;
  date: string;
  time: string;
  available: number;
  isPeak: boolean;
  price: number;
}



export interface AvailableStartingTimesDuration {
  id: string;
  serviceId: string;
  isWeekend: boolean;
  isWeekday: boolean;
  currency: string;
  price: number;
  durationTime: number;
  slots: AvailableStartingTimesSlot[];
  timeMultipleOf: number;
  peakPricePerDurationMultiple: number;
  offPeakPricePerDurationMultiple: number;
}

export interface AvailableStartingTimesResource {
  active?: string;
  name?: string;
  id: string;
  branchId?: string;
  companyId?: string;
  companyName?: string;
  companyLogo?: string;
  area?: string;
  duration: AvailableStartingTimesDuration[];
  service?: AvailableStartingTimesService;
  timezone?: string;
}

interface AvailableStartingTimesService {
  companyId: string;
  branchId: string;
  description: string;
  duration: AvailableStartingTimesDuration[];
  addons: Addon[];
  type: "class" | "coach" | "standard";
  subType?: "fun" | "gym" | "kids" | "fitness";
  id: string;
  serviceDurationMultiples: number;
  cutoff?: number;
  isActive: boolean;
}

export interface AvailableStartingTimesOutput {
  id: string;
  address: string;
  timezone: string;
  service: AvailableStartingTimesService;
  companyName?: string;
  companyLogo?:string;
  area?: string;
}

export interface DayAvailability {
  date: string;
  availability: AvailableStartingTimesOutput;
}


const useAvailableStartingTimes = ({
  companyId,
  branchId,
  serviceId,
  selectedDay,
  selectedResource,
  bookingCart,
  selectedPickerDate
}: AvailableStartingTimesInput) => {
  const {
    setAvailableStartingTimesResource,
    setSlotsMap,
    setSelectedDuration,
    setCurrentRangeAvailability,
    currentRangeAvailability
  } = useContext(CheckoutContext) as CheckoutContextProps;
  const [loading, setLoading] = useState<boolean>(false);
  const [currentService, setCurrentService] = useState<string | undefined>();
  const [currentResource, setCurrentResource] = useState<string | undefined | null>();
  const stringifiedBookingCart = JSON.stringify(bookingCart);
  useEffect(() => {
    const abortController = new AbortController();

    const fetchAvailableStartingTimes = async () => {


      setLoading(true);
      try {

        let output;

        if (shouldRefetchAvailability(currentRangeAvailability, selectedPickerDate, serviceId, currentService , selectedResource , currentResource)) {
          const { data: response } = await axiosInstance.post(
            `/booking/getAvailableStartingTimesForMultipleDates`,
            {
              companyId,
              branchId,
              serviceId,
              dates: [dayjs(selectedPickerDate).format("DD/MM/YYYY"), dayjs(selectedPickerDate).add(6, 'days').format("DD/MM/YYYY")],
              resourceId: selectedResource,
            },
            {
              signal: abortController.signal,
            }
          );

          setCurrentRangeAvailability(response.data);
          setCurrentService(serviceId);
          setCurrentResource(selectedResource);
          output = response.data.filter((dayAvailability: any) => dayAvailability.date === dayjs(selectedDay, "DD/MM/YYYY").format("YYYYMMDD"))[0]?.availability || [];
        }
        else {
          const selectedDayAvailability = currentRangeAvailability.filter((dayAvailability: any) => dayAvailability.date === dayjs(selectedDay, "DD/MM/YYYY").format("YYYYMMDD"))[0]?.availability;
          output = selectedDayAvailability;
        }

        const availableStartingTimesResource =
          getResourceAvailableStartingTimes(output, selectedResource!);
        setAvailableStartingTimesResource(availableStartingTimesResource);
        const slotsMap = getAvailableBookingSlots(
          availableStartingTimesResource
        );
        setSlotsMap(slotsMap);
        setSelectedDuration(availableStartingTimesResource.duration?.length > 0 ? availableStartingTimesResource.duration[0] : undefined);
        setLoading(false);
      } catch (e: any) {
        console.log("TEST TEST", e);
        setCurrentRangeAvailability([]);
        setAvailableStartingTimesResource(undefined);
        setSlotsMap(new Map<string, AvailableStartingTimesSlot[]>());
        if (e.code !== "ERR_CANCELED") setLoading(false);
      }
    };

    if (companyId && branchId && serviceId && selectedDay) {
      fetchAvailableStartingTimes();
    }

    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPickerDate,
    selectedDay,
    selectedResource,
    branchId,
    serviceId,
    stringifiedBookingCart,
  ]);
  return { loading };
};

export const getResourceAvailableStartingTimes = (
  availableStartingTimesOutput: AvailableStartingTimesOutput,
  selectedResource: string
): AvailableStartingTimesResource => {
  // sort by duration
  availableStartingTimesOutput?.service?.duration.sort((a, b) => {
    return a.durationTime - b.durationTime;
  });

  const resourceAvailableStartingTimes: AvailableStartingTimesResource = {
    duration: availableStartingTimesOutput?.service?.duration,
    id: selectedResource,
  };

  return resourceAvailableStartingTimes;
};

export const getAvailableBookingSlots = (
  availableStartingTimesResource: AvailableStartingTimesResource
) => {
  const bookingSlots: Map<string, AvailableStartingTimesSlot[]> = new Map();
  availableStartingTimesResource?.duration?.forEach((duration) => {
    const availableSlots: AvailableStartingTimesSlot[] = [];
    duration.slots.forEach((slot) => {
      if (slot.available <= 0) return;
      availableSlots.push(slot);
    });
    bookingSlots.set(duration.id, availableSlots);
  });

  return bookingSlots;
};

const shouldRefetchAvailability = (currentRangeAvailability: DayAvailability[], selectedPickerDate: Date, serviceId?: string, currentService?: string , resourceId?: string | null, currentResource?: string | null ) => {
  if (serviceId !== currentService) return true;
  if (resourceId !== currentResource) return true;
  for (let index = 0; index < 7; index++) {
    const currentDate = dayjs(selectedPickerDate).add(index, 'days').format("YYYYMMDD");
    const currentDayAvailability = currentRangeAvailability.filter((dayAvailability: any) => dayAvailability.date === currentDate)[0];
    if (!currentDayAvailability) return true;
  }
  return false;
}

export default useAvailableStartingTimes;
