import { Box, Typography, useTheme } from "@mui/material";
import React, { useContext, useMemo } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import {
  AvailableStartingTimesResource
} from "../../../hooks/useAvailableStartingTimes";
import { getCurrency, isAfterCutOff, withinWorkingHours } from "../../../Utils/format";
import SlotsSection from "./SlotsSection";
import { AvailableStartingTimesWrapper } from "./style";
import { isInBookingRange } from "../../../Utils/validate";

interface IProps {
  availableStartingTimes: AvailableStartingTimesResource;
  addToCart: any;
  timeUnit: "Hour" | "Session";
  startTime?: string;
  endTime?: string;
}
const AvailableStartingTimes: React.FC<IProps> = ({
  availableStartingTimes,
  addToCart,
  timeUnit,
  startTime,
  endTime,
}) => {

  const theme = useTheme();
  const {
    company,
    quantity,
    selectedService,
    servicesMap,
    selectedDuration,
    slotsMap
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const service = servicesMap?.get(selectedService);




  const morningStartingTimes = useMemo(() => {
    const bookingSlots = slotsMap.get(selectedDuration?.id!)
    if (!bookingSlots || !selectedDuration) return [];
    return bookingSlots
      .filter((slot) => isInBookingRange(company, slot.fullDate))
      .filter((slot) => {
        const isMorningSlot = slot.available >= quantity && slot.time < "1200";
        return (
          isMorningSlot &&
          isAfterCutOff(service?.cutoff, slot.fullDate, company?.timezone) &&
          (service?.hasOwnProperty("isActive") ? service.isActive : true) &&
          withinWorkingHours(slot.time, startTime, endTime)
        );
      });
  }, [selectedDuration, quantity, service, company?.timezone]);

  const afternoonStartingTimes = useMemo(() => {
    const bookingSlots = slotsMap.get(selectedDuration?.id!)
    if (!bookingSlots || !selectedDuration) return [];
    return bookingSlots
      .filter((slot) => isInBookingRange(company, slot.fullDate))
      .filter((slot) => {
        const isAfternoonSlot =
          slot.available >= quantity && slot.time >= "1200";


        return (
          isAfternoonSlot &&

          isAfterCutOff(service?.cutoff, slot.fullDate, company?.timezone) &&
          (service?.hasOwnProperty("isActive") ? service.isActive : true) &&
          withinWorkingHours(slot.time, startTime, endTime)
        );
      });
  }, [selectedDuration, quantity, service, company?.timezone]);

  const getPrice = (price: number, duration: number) => {
    if (timeUnit === "Hour") return (duration / (service?.serviceDurationMultiples || company?.serviceDurationMultiples || 15)) * price * quantity;
    return price * quantity;
  };


  const areAllOffPeakSlots = () => {
    let allOffPeakSlots = true
    morningStartingTimes.forEach(slot => {
      if (slot.isPeak) {
        allOffPeakSlots = false;
      }
    });
    afternoonStartingTimes.forEach(slot => {
      if (slot.isPeak) {
        allOffPeakSlots = false;
      }
    });
    return allOffPeakSlots;
  }

  const areAllPeakSlots = () => {
    let allPeakSlots = true
    morningStartingTimes.forEach(slot => {
      if (!slot.isPeak) {
        allPeakSlots = false;
      }
    });
    afternoonStartingTimes.forEach(slot => {
      if (!slot.isPeak) {
        allPeakSlots = false;
      }
    });
    return allPeakSlots;
  }

  const getPriceCard = () => {

    if (areAllPeakSlots()) {
      return (
        <Typography className="label">
          {`${getPrice(
            selectedDuration?.peakPricePerDurationMultiple!,
            selectedDuration?.durationTime!
          )} ${getCurrency(company?.currency)} ${timeUnit === "Session" ? "/Session" : ""}`}
        </Typography>)
    }
    else if (areAllOffPeakSlots()) {
      return (
        <Typography className="label">
          {`${getPrice(
            selectedDuration?.offPeakPricePerDurationMultiple!,
            selectedDuration?.durationTime!
          )} ${getCurrency(company?.currency)} ${timeUnit === "Session" ? "/Session" : ""}`}
        </Typography>
      )
    } else if (selectedDuration?.peakPricePerDurationMultiple === selectedDuration?.offPeakPricePerDurationMultiple) {
      return (
        <Typography className="label">
          {`${getPrice(
            selectedDuration?.peakPricePerDurationMultiple!,
            selectedDuration?.durationTime!
          )} ${getCurrency(company?.currency)} ${timeUnit === "Session" ? "/Session" : ""}`}
        </Typography>)
    } else if (selectedDuration?.peakPricePerDurationMultiple !== selectedDuration?.offPeakPricePerDurationMultiple) {
      return (
        <>
          <Typography className="label">
            {`${getPrice(
              selectedDuration?.peakPricePerDurationMultiple!,
              selectedDuration?.durationTime!
            )} ${getCurrency(company?.currency)} ${timeUnit === "Session" ? "/Session" : ""}`}
          </Typography>
          <Typography className="label">{`Off-Peak: ${getPrice(
            selectedDuration?.offPeakPricePerDurationMultiple!,
            selectedDuration?.durationTime!
          )} ${getCurrency(company?.currency)} ${timeUnit === "Session" ? "/Session" : ""}`}</Typography>
        </>
      )
    }
  }




  return (
    <AvailableStartingTimesWrapper theme={theme} className="available-times">
      {(morningStartingTimes.length > 0 || afternoonStartingTimes.length > 0) &&
        <>
          <Typography className="availability-Label">
            Available Starting Times
          </Typography>
          <Box className="price-section">
            <Typography className="label">Price</Typography>

            {getPriceCard()}
          </Box>
        </>
      }
      {morningStartingTimes.length > 0 || afternoonStartingTimes.length > 0 ? (
        <Box
          sx={{
            marginTop: "35px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          {!!morningStartingTimes.length && (
            <SlotsSection
              label="Morning"
              slots={morningStartingTimes}
              availableStartingTimes={availableStartingTimes}
              addToCart={addToCart}
              areAllOffPeakSlots={areAllOffPeakSlots()}
            />
          )}
          {!!afternoonStartingTimes.length && (
            <SlotsSection
              label="Afternoon"
              slots={afternoonStartingTimes}
              availableStartingTimes={availableStartingTimes}
              addToCart={addToCart}
              areAllOffPeakSlots={areAllOffPeakSlots()}
            />
          )}
        </Box>
      ) : (
        <Typography className="no-slots-label">
          Ughh, Seems like we are fully booked today. Join on us on another day?
        </Typography>
      )}
    </AvailableStartingTimesWrapper>
  );
};
export default AvailableStartingTimes;
