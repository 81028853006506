import {
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { IChildInputProps } from "./Types";

const ChildInput: React.FC<IChildInputProps> = ({
  errors,
  register,
  field,
  index,
}) => {
  return (
    <Stack key={field.id} sx={{ width: "100%" }}>
      <InputLabel htmlFor="child-1" sx={{ fontSize: "24px", color: "#33334F" }}>
        Name of Child
      </InputLabel>

      <Stack
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "15px",
          width: "100%",
        }}
      >
        <Input
          id="child-1"
          type="text"
          placeholder="Please enter name"
          sx={{
            border: "1px solid #D1D5DB",
            height: "42px",
            borderRadius: "6px",
            px: "20px",
            flex: 1,
            "&.Mui-error": {
              border: "1px solid #C82E34",
            },
            "&::before": {
              content: "none",
            },
          }}
          {...register(`children.${index}.name`, {
            required: "Child name is required",
          })}
          error={!!errors.children?.[index]?.name}
        />

        <Select
          placeholder="Age"
          defaultValue={0}
          sx={{
            borderColor: "#D1D5DB",
            height: "42px",
            borderRadius: "6px",
            px: "18px",
            width: "111px",
          }}
          {...register(`children.${index}.age`, {
            required: "Child age is required",
            min: { value: 4, message: "Child age is required" },
          })}
          error={!!errors.children?.[index]?.age}
        >
          <MenuItem value={0} disabled>
            <Typography component={"span"} sx={{ color: "#808080" }}>
              Age
            </Typography>
          </MenuItem>
          {new Array(9).fill(0).map((_, i) => (
            <MenuItem value={i + 4} key={`${index}-${i}`}>
              {i + 4}
            </MenuItem>
          ))}
        </Select>
      </Stack>

      {errors.children?.[index] && (
        <>
          <Typography
            sx={{ fontSize: "12px", color: "#C82E34", marginTop: "8px" }}
          >
            {errors.children?.[index]?.name?.message}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "#C82E34", marginTop: "8px" }}
          >
            {errors.children?.[index]?.age?.message}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default ChildInput;
