import { List, ListItem, Stack, Typography } from "@mui/material";
import React from "react";
import Heading from "../../components/shared/Heading";
import LandingPageTitle from "../../components/shared/LandingPageTitle";

const CustomizedListItem = ({ children }: any) => (
  <ListItem style={{ fontFamily: "Roboto" }}>{children}</ListItem>
);

const Terms = () => {
  return (
    <Stack sx={{ marginTop: 2, gap: 3 }}>
      <Heading heading="Terms & Conditions" />

      <Stack>
        <Typography>
          Dubai World Trade Centre L.L.C. provides these Terms and Conditions to
          inform you of our Legal Policy and practices. Please carefully review
          these Terms and Conditions before using this website. Your use of this
          Website indicates your irrevocable agreement to be bound by these
          Terms and Conditions.
        </Typography>
        <Typography>
          The dubaisportsworld.ae website and its sub-domains are owned by Dubai
          World Trade Centre L.L.C., trading as Dubai World Trade Centre, a
          limited liability company incorporated in Dubai, United Arab Emirates,
          and having its head office at Dubai International Convention and
          Exhibition Centre, Sheik Zayed Road, Convention Gate, P.O. Box 9292,
          Dubai, UAE.
        </Typography>
        <Typography component={"span"}>
          Telephone: +971-4-3321000, Fax: +971-4-3312173.
        </Typography>

        <Typography>
          For the purposes of these Terms and Conditions "we", "our" and "us"
          refer to Dubai World Trade Centre L.L.C., and "you" and "“your" refer
          to a specific individual or program accessing this website.
        </Typography>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="General Terms" />
        <List>
          <CustomizedListItem>
            Booking can be cancelled only online and at least 3 days prior to
            the date of your booking. The refunded amount will be credited to
            your profile in the form of DSW package and this can be used within
            the DSW season for this year and cannot be carry forwarded.
          </CustomizedListItem>
          <CustomizedListItem>
            Booking is only confirmed when payment receipt is issued and the
            booking confirmation is sent to your registered email.
          </CustomizedListItem>
          <CustomizedListItem>
            Allow yourself and your guests/visitors plenty of time to reach
            Dubai Sports World to ensure you are ready to start your game on
            time. Late arrival or delays: in case of late arrival, the DSW duty
            manager onsite will evaluate and potentially reduce the duration of
            your session, in its sole discretion.
          </CustomizedListItem>
          <CustomizedListItem>
            Out of courtesy to other users, please keep all people associated
            with your booking clear of the courts and pitches until your booking
            is due to start. Kindly vacate the courts and pitches on time at the
            end of your booked activity.
          </CustomizedListItem>
          <CustomizedListItem>
            Any personal adjustments to the existing court or pitch set up will
            be included in the activity period window (e.g adjusting the height
            of the volleyball nets).
          </CustomizedListItem>
          <CustomizedListItem>
            For the benefit of all customers and staff, visitors to, and
            participants of, the booked activity must cooperate with and follow
            the instructions of the manager or staff on duty at all times.
          </CustomizedListItem>
          <CustomizedListItem>
            Any public announcements, scores or amplified music must be kept to
            a minimum level. The duty manager is entitled to remove these
            speakers and microphone if the volume is disturbing the other
            guests.
          </CustomizedListItem>
          <CustomizedListItem>
            All players, spectators and the accompanied visitors must follow the
            admission procedure and ensure valid information is provided to
            complete their check-in at the entrance, Dubai Sports World reserves
            the right to refuse the admission in failure to complete the
            check-in process. Avoid the queue and fast-pass check-in: Download
            Dubai Sports World app to complete the registration process and
            check-in prior your arrival.
          </CustomizedListItem>
          <CustomizedListItem>
            Dubai Sports World and Dubai World Trade Centre cannot be held
            responsible for any sports related injuries, loss or damage caused
            or alleged to be caused directly or indirectly as a result of the
            use or interpretation of the material, or from taking part in any
            activity or event, including all DSW partner activities.
          </CustomizedListItem>
          <CustomizedListItem>
            DSW shall not be liable for the actions, omissions, or advice of any
            third party that has been introduced by Dubai World Trade Centre to
            participants as part of DSW.
          </CustomizedListItem>
          <CustomizedListItem>
            Dubai Sports World shall not be liable to any party for any losses
            damage or expenses arising out of any circumstance which may cause
            the facility to be temporarily closed.
          </CustomizedListItem>
          <CustomizedListItem>
            Dubai Sports World shall not be liable to any party for the booking
            to be interrupted delayed or cancelled. Alternative slot, pitch and
            court bookings will be arranged in such situations.
          </CustomizedListItem>
          <CustomizedListItem>
            DSW has limited number of sports equipment (e.g balls, shuttlecocks,
            rackets etc.) they are subject to availability, it is strongly
            advisable to bring your own sports equipment with you.
          </CustomizedListItem>
          <CustomizedListItem>
            Eating and drinking on the courts or pitches is prohibited.{" "}
          </CustomizedListItem>
        </List>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="DSW Packages" />
        <List>
          <CustomizedListItem>
            ALL DSW Packages are valid for the current year/season only. Unused
            values will not be refunded or attributed to any transactions for
            next season.
          </CustomizedListItem>
          <CustomizedListItem>
            DSW Packages can be redeemed against all pitch rentals, add-ons,
            classes, kids’ area and Gym memberships
          </CustomizedListItem>
          <CustomizedListItem>
            DSW Packages cannot be redeemed for cash, returned for a refund, or
            be replaced after expiry and are not legal tender, account cards,
            credit or debit cards or securities.
          </CustomizedListItem>
          <CustomizedListItem>
            If your purchase exceeds the value of the gift voucher, balance must
            be paid by online credit card payment.
          </CustomizedListItem>
          <CustomizedListItem>
            If you cancel any bookings that you have purchased using a gift
            voucher, the balance will be credited to you on the package and is
            to be redeemed against subsequent purchases.
          </CustomizedListItem>
        </List>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Dubai Kids World" />
        <Stack>
          <List>
            <CustomizedListItem>
              Each kids zone in Dubai Kids World has the below height
              requirements:
            </CustomizedListItem>
            <Typography sx={{ ml: 3 }}>
              <b>Adventure Zone</b>: 110cm-160cm
              <br />
              <b>Soft Play Zone</b>:90cm-140cm
              <br />
              <b>Jump Zone</b>: 90cm-160cm
              <br />
              <b>Maze Cube</b>:90cm-160cm
              <br />
              <b>Bouncy Obstacles</b>: 110cm-160cm
              <br />
              <b>The Play Houses</b>: 90cm-140cm
              <br />
              <b>Creative Corner</b>: 90cm-160cm
              <br />
              <b>Party & Grub Zone</b>: No Height Req
            </Typography>

            <CustomizedListItem>
              Each person entering Dubai Kids World must wear a wristband to
              distinguish their passes.
            </CustomizedListItem>
            <Typography sx={{ ml: 3 }}>
              <b>Yellow:</b> 2-hour
              <br />
              <b>Pink:</b> Full Day Pass
              <br />
              <b>Aqua Blue:</b> Dubai kids World Membership
              <br />
              <b>Orange:</b> Celebration at Dubai Kids World
            </Typography>

            <CustomizedListItem>
              Dubai Kids World is a supervised facility, open to all children
              from 6-12 years old with a minimum height of 110cm and a maximum
              height of 160cm.
            </CustomizedListItem>
            <CustomizedListItem>
              Children between 4-5 years old should be at least 90cm and
              accompanied by an adult to enter the Dubai Kids World.
            </CustomizedListItem>
            <CustomizedListItem>
              Kids and accompanying adults must wear the colour appropriate
              wristband to enter.
            </CustomizedListItem>
            <CustomizedListItem>
              The booking confirmation with the QR code must be presented to
              enter Dubai Kids World.
            </CustomizedListItem>
            <CustomizedListItem>
              Entry will not be allowed without a wristband and QR code.
            </CustomizedListItem>
            <CustomizedListItem>
              Dubai Kids World socks must be worn at all times to ensure good
              grip.
            </CustomizedListItem>
            <CustomizedListItem>
              Each zone has a minimum & maximum height requirement. Children who
              are below or over the height requirement, will not be allowed
              access to that particular zone.
            </CustomizedListItem>
            <CustomizedListItem>
              Food and beverages from outside are not allowed.{" "}
            </CustomizedListItem>
            <CustomizedListItem>
              Personal water bottles are allowed inside Dubai Kids World.
            </CustomizedListItem>
          </List>
        </Stack>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Dubai Kids World Membership" />
        <List>
          <CustomizedListItem>
            DSW reserves the right to reject an application for membership at
            their absolute discretion. DSW also reserves the right to verify or
            require proof of all information given. Any fraudulent or wrongful
            information given to obtain a membership could result in the
            cancellation of all membership rights.
          </CustomizedListItem>
          <CustomizedListItem>
            All Dubai Kids World Membership holders should book their time slot
            before coming to the venue.
          </CustomizedListItem>
          <CustomizedListItem>
            The 30-day membership will take effect from the purchase day.
          </CustomizedListItem>
          <CustomizedListItem>
            Dubai Kids World Membership cannot be cancelled even if it’s unused.
          </CustomizedListItem>
          <CustomizedListItem>
            All Dubai Kids World Membership will expire on the last day of Dubai
            Sports World irrespective of their effective start date.
          </CustomizedListItem>
          <CustomizedListItem>
            While every precaution is taken to maintain safety standards, all
            equipment and facilities are used entirely at the visitor’s own
            risk. Any malfunction of equipment should be reported to a member of
            the DSW staff.
          </CustomizedListItem>
          <CustomizedListItem>
            For safety and hygiene purposes, no eating is allowed inside the
            Dubai Kids World area.
          </CustomizedListItem>
          <CustomizedListItem>
            Members must secure their personal belongings on their own. Dubai
            Sports World, its employees, agents, and subcontractors are not
            liable for any loss, damage or theft of any personal belongings on
            the premises.
          </CustomizedListItem>
          <CustomizedListItem>
            Soliciting and selling private personal training sessions or
            merchandise of any type to any visitor is prohibited and may result
            in immediate termination of the Dubai Kids World membership.
          </CustomizedListItem>
          <CustomizedListItem>
            Dubai Kids World membership members and/or visitors are not allowed
            to photograph or copy any Dubai Sports World designs, promotional
            materials, taglines, themes or concepts. Dubai Sports World reserves
            the right to take legal action against any member caught
            distributing, sharing or supplying such content to a third-party
            vendor or competitor.
          </CustomizedListItem>
        </List>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="PHOTOGRAPHY/ VISUALS RELEASE DISCLAIMER" />
        <List>
          <CustomizedListItem>
            You hereby agree and allow the Dubai World Trade Centre (LLC)
            (DWTC), its employees and authorised agents, to record and use
            photographs/visuals (Images) of your event at Dubai Sport World.
          </CustomizedListItem>
          <CustomizedListItem>
            You further give your irrevocable express consent to DWTC to
            publish, republish or otherwise transmit these Images in any type of
            visual medium, including but not limited to photographs, digital
            images, drawings, renderings and video recordings, throughout the
            world.
          </CustomizedListItem>
          <CustomizedListItem>
            You hereby waive all and any of your rights in these Images.
          </CustomizedListItem>
          <CustomizedListItem>
            You understand and agree that these Images may be used in any manner
            or media without the need to notify you orally or in writing, for
            marketing, publications, reports, promotions, broadcasts, displays,
            advertisements, websites, electronic or digital recordings,
            informational or any other purpose concerning DWTC and its services.
            You expressly waive any right to inspect or approve such Images in
            their final form, or any printed or electronic related matter that
            may be used in relation to them, and You understand and agree that
            you will not be entitled to any compensation/remuneration for use of
            these Images.
          </CustomizedListItem>
          <CustomizedListItem>
            You also agree to release DWTC, its employees and authorised agents
            (including those authorised to publish and/or distribute any
            finished product containing any element, in whole or in part, of the
            Images) from any and all liability (foreseeable or not) arising out
            of or connected to the use of these Images.
          </CustomizedListItem>
          <CustomizedListItem>
            You have read and understand the foregoing consent, release, and
            waiver, and voluntarily accept and agree to the above terms.
          </CustomizedListItem>
        </List>
      </Stack>

      <Stack gap={2}>
        <LandingPageTitle title="Deletion of Account" />
        <Stack>
          <Typography>
            Users can delete their Dubai Sports World account by following these
            steps:
          </Typography>
          <List>
            <CustomizedListItem>
              On the Dubai Sports World app, click on the “My profile” page in
              the navigation.
            </CustomizedListItem>
            <CustomizedListItem>Click on “Delete Account”</CustomizedListItem>
            <CustomizedListItem>
              If there are any active bookings, these bookings will be cancelled
              with no refund, and both the user account and booking(s) will be
              deleted.
            </CustomizedListItem>
          </List>
          <Typography>
            Once the account is deleted, Dubai Sports World does not save any
            personal data and all active bookings are cancelled with no refund.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Terms;
