import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { down } from "styled-breakpoints";

export const ClassSessionSelectorWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-top: 30px;
  margin-bottom: 30px;
  ${down("sm")} {
    overflow: auto;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const ClassSessionChipWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  &:hover {
    cursor: pointer;
  }
  box-shadow: 0 6px 40px rgba(208, 208, 208, 0.4);
  border-radius: 5px;
  padding: 15px;
  width: 90%;
  margin-top: 15px;

  .header {
    margin-left: 15px;
    margin-bottom: 10px;
    font-size: 25px;
    text-align: center;
    font-weight: normal;
    line-height: 140%;
  }

  .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    padding: 10px;

    .item {
      // font-family: Roboto-thin;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
    }
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      width:100%;
      height: auto;
      border-radius: 8px;
    }
  }

  .book-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    margin-top: 10px;
  }
`;
