import styled from "@emotion/styled";
import { down } from "styled-breakpoints";
export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  // width: 80%;
  text-align: center;
  width: 100%;

  ${down("sm")} {
    width: 100%;
  }
`;
