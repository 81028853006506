import { Box, InputLabel, Typography, useTheme } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { IPhoneFormInput } from "./Types";

const PhoneFormInput: React.FC<IPhoneFormInput> = ({ control, error }) => {
  const theme = useTheme();
  return (
    <Box sx={{ marginBottom: "20px" }}>
      <InputLabel
        sx={{
          fontSize: "18px",
          fontWeight: 500,
          marginBottom: "16px",
          alignSelf: "flex-start",
          color: "#171717",
        }}
      >
        Phone Number *
      </InputLabel>

      <Controller
        control={control}
        name="phoneNumber"
        render={({ field }) => (
          <PhoneInput
            {...field}
            country={"ae"}
            buttonStyle={{
              position: "static",
              border: "1px solid #D1D5DB",
              borderRadius: "6px",
              padding: "9px 18px",
              background: "#fff",
            }}
            containerStyle={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "14px",
              height: "42px",
            }}
            inputStyle={{
              flex: 1,
              padding: "10px 18px",
              borderRadius: "6px",
              border: "1px solid #D1D5DB",
              fontSize: "16px",
              height: "42px",
              width: "100%"
            }}
          />
        )}
      />
      {error && (
        <Typography
          sx={{ fontSize: "12px", color:theme.palette.primary.main, marginTop: "8px" }}
        >
          {error.message}
        </Typography>
      )}
    </Box>
  );
};

export default PhoneFormInput;
