import { Session } from "../../../hooks/useClassSessions";
import ContentLoader from "../../shared/ContentLoader";
import ClassSessionChip from "./ClassSessionChip";
import { ClassSessionSelectorWrapper } from "./style";
import Heading from "../../shared/Heading";
import { alphabeticalSort } from "../../../Utils/sort";
import dayjs from "dayjs";

interface IProps {
  sessions: Session[];
  isLoading: boolean;
  type: "fun" | "fitness" | "coach";
}
const ClassSessionSelector: React.FC<IProps> = ({
  sessions,
  isLoading,
  type,
}) => {
  const getHeadingTitle = () => {
    if (type === "fitness" && sessions.length === 0) {
      return "No Classes Available";
    }

    if (type === "fitness" && sessions.length > 0) {
      return "Available Classes";
    }

    if (type === "coach" && sessions.length === 0) {
      return "No Coaches Available";
    }

    if (type === "coach" && sessions.length > 0) {
      return "Available Coaches";
    }

    if (type === "fun" && sessions.length === 0) {
      return "Stay active this summer with DSW's fitness masterclasses. Join our expert-led sessions in Zumba, Yoga, and more—start moving today.";
    }

    if (type === "fun" && sessions.length > 0) {
      return "";
    }



    return "";
  };
  return (
    <ContentLoader isLoading={isLoading}>
      <ClassSessionSelectorWrapper>
        <Heading heading={getHeadingTitle()} />

        {sessions
          .sort((a, b) => alphabeticalSort(`${a.date}${a.startTime}`, `${b.date}${b.startTime}`))
          .filter(
            (session) =>
              session.available > 0 &&
              getClassFullDate(session).isAfter(dayjs())
          )
          .map((session) => (
            <ClassSessionChip key={session.id} session={session} />
          ))}
      </ClassSessionSelectorWrapper>
    </ContentLoader>
  );
};

export default ClassSessionSelector;

const getClassFullDate = (session: Session) => {
  return dayjs(`${session.date} ${session.startTime}`, "YYYYMMDD HHmm");
};
