import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const RegisterWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 770px;
  // height: 100%;
  margin-top: 20px;
`;
