import React from "react";
import { Card, Box, Typography, Stack, Button, useTheme } from "@mui/material";
import { SessionCardProps } from "./Type";
import SessionCardDate from "./SessionCardDate";
import SessionRow from "./SessionRow";
import CardAddonRow from "../cart/CardAddonRow";
import { AddonPurchase } from "../../hooks/useCart";
import { formatStringTime } from "../../Utils/format";

const SessionCard: React.FC<SessionCardProps> = ({
  id,
  date,
  resource,
  newCartTotal,
  service,
  startTime,
  price,
  quantity,
  addonPurchases,
  serviceDuration,
  onCancelBookingHandler,
  isCancelAddon = true,
  isCancelBooking = false,
}) => {
  const theme = useTheme();

  const cancelProfileBooking = () => {
    if (onCancelBookingHandler)
      onCancelBookingHandler(id, date, price, startTime);
  };

  return (
    <Card
      sx={{
        position: "relative",
        paddingRight: "16px",
        boxShadow: "0 6px 40px rgba(208, 208, 208, 0.2)",
        border: 0,
        borderRadius: "0 16px 16px 0",
        width: "100%",
        overflow: "visible",
        marginBottom: isCancelBooking ? "20px" : 0,
      }}
    >
      {/* Cancel booking */}
      {isCancelBooking && (
        <Button
          sx={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: theme.palette.primary.main,
            fontSize: "12px",
            fontWeight: 500,
            bgcolor: "#FDE9F1",
            height: "20px",
            width: "130px",
            boxShadow: "0 6px 40px rgba(208, 208, 208, 0.2)",
            borderRadius: "6px",
            clipPath: "polygon(0% 100%, 85% 100%, 100% 0%, 0% 0%)",
            bottom: "-20px",
            right: 0,
            textTransform: "none",
            "&:hover": {
              bgcolor: "#FDE9F1",
            },
          }}
          onClick={cancelProfileBooking}
        >
          Cancel Booking
        </Button>
      )}

      <Stack direction={"row"}>
        <SessionCardDate day={date.slice(6, 8)} month={date.slice(4, 6)} />
        <Box sx={{ padding: "15px", width: "100%" }}>
          <Stack
            sx={{
              gap: "12px",
              flexWrap: "wrap",

              "@media (min-width: 768px)": {
                flexDirection: "row",
                alignItems: "center",
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{ textAlign: "left", fontSize: "20px" }}
            >
              {`${resource?.resourceName || service.serviceName} ${quantity && quantity > 1 ? `x${quantity}` : ""
                }`}
            </Typography>
            <SessionRow
              startTime={formatStringTime(startTime)}
              duration={serviceDuration.durationTime || 0}
              price={price * quantity}
              currency={service.currency || ""}
            />
          </Stack>
          {!!addonPurchases?.length &&
            addonPurchases.map((addon: AddonPurchase) => (
              <CardAddonRow
                key={addon.id}
                addon={addon}
                isCancelAddon={isCancelAddon}
              />
            ))}
        </Box>
      </Stack>
    </Card>
  );
};

export default SessionCard;
