import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import CartCheckoutDetails from "../../components/cart/CartCheckoutDetails";
import ConfirmationHeader from "../../components/confirmation/ConfirmationHeader";
import ConfirmationBookingDetails from "../../components/shared/bookingDetails/ConfirmationBookingDetails";
import useTitle from "../../hooks/useTitle";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import { ConfirmationWrapper } from "./style";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";
import { DSW_COMPANY_ID } from "../../Utils/constants";

interface IProps {
  title: string;
}
const Confirmation: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const cartId = searchParams.get("cartId") || "";
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;
  
  return (
    <ConfirmationWrapper>
      <ConfirmationHeader searchParams={searchParams} />
      {cartId ? (
        <CartCheckoutDetails cartId={cartId} isCancelBooking={true} shouldRedirectToCancel={true}/>
      ) : (
        <ConfirmationBookingDetails searchParams={searchParams} />
      )}
      <Typography sx={{ my: 3 }} className="email-label">
        {company?.confirmationDisclaimer}
      </Typography>
      <div style={{ width: "100%", marginBottom: "32px" }}>
        <PrimaryBtn
          text="Back To Home"
          onClick={() => {
            window.open(company?.website ||"","_self")
          }}
        />
      </div>
    </ConfirmationWrapper>
  );
};

export default Confirmation;
