import React, { useContext, useMemo, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
  InputLabel,
  useTheme,
  IconButton,
} from "@mui/material";
import { Link } from "react-router-dom";
import countryList from "react-select-country-list";
import PrimaryBtn from "../../FormCollection/PrimaryBtn";
import Heading from "../../shared/Heading";
import FormSelect from "../../FormCollection/FormSelect";
import FormInput from "../../FormCollection/FormInput";
import PhoneFormInput from "./PhoneFormInput";
import { IFormUIProps } from "./Types";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Genders, sports, whereDidYouHearAboutUs } from "./data";
import { Controller } from "react-hook-form";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const FormUI: React.FC<IFormUIProps> = ({
  onSubmit,
  form,
  isSignupWithEmailAndPassword,
  isLoading,
}) => {
  const theme = useTheme();
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword , setShowConfirmPassword] = useState(false);


  const options = useMemo(() => countryList().getData(), []);
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    watch,
  } = form;


  return (
    <>
      <Heading
        heading={
          isSignupWithEmailAndPassword
            ? "Create an Account"
            : "Complete Your Profile"
        }
        sx={{ marginTop: "20px" }}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          gap: "20px",
          width: "100%",
          marginBottom: "40px",
        }}
      >
        <FormInput
          label="First Name*"
          error={errors.firstName}
          placeholder={"Enter First Name"}
          register={register("firstName")}
          id="firstName"
        />

        <FormInput
          label="Last Name*"
          error={errors.lastName}
          placeholder={"Enter Last Name"}
          register={register("lastName")}
          id="lastName"
        />

        <PhoneFormInput control={control} error={errors["phoneNumber"]} />

        <FormSelect
          label="Gender"
          placeholder={"gender"}
          id="gender"
          sx={{
            height: "70px",
            width: "100%",
            borderRadius: "16px",
            background: "#fff",
            boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
            fontSize: "16px",
          }}
          options={Genders}
          register={register("gender")}
          error={errors.gender}
        />

        <InputLabel
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            alignSelf: "flex-start",
            color: "#171717",
          }}
        >
          Date of Birth*
        </InputLabel>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Controller
            control={control}
            name="dateOfBirth"
            render={({ field: { ...field } }) => (
              <DatePicker
                {...field}
                views={["year", "month", "day"]}
                sx={{
                  border: 0,
                  height: "70px",
                  boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
                  borderRadius: "16px",
                  pl: "16px",
                  width: "100%",
                  "&::before": {
                    content: "none",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                  {
                    border: 0,
                  },
                  "& .MuiOutlinedInput-input": {
                    height: "70px",
                    boxSizing: "border-box",
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>

        <Typography
          sx={{ fontSize: "12px", color: theme.palette.primary.main, marginTop: "8px" }}
        >
          {errors.dateOfBirth?.message as string}
        </Typography>

        <FormSelect
          label="How did you hear about us?*"
          placeholder={"whereDidYouHearAboutUs"}
          id="whereDidYouHearAboutUs"
          sx={{
            height: "70px",
            width: "100%",
            borderRadius: "16px",
            background: "#fff",
            boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
            fontSize: "16px",
          }}
          options={whereDidYouHearAboutUs}
          register={register("whereDidYouHearAboutUs")}
          error={errors.whereDidYouHearAboutUs}
        />

        <FormSelect
          label="Favorite Sports*"
          placeholder={"Favorite Sports"}
          id="favoriteSports"
          sx={{
            height: "70px",
            width: "100%",
            borderRadius: "16px",
            background: "#fff",
            boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
            fontSize: "16px",
          }}
          options={sports}
          register={register("favoriteSports")}
          error={ errors?.favoriteSports}
          multi
          value={watch("favoriteSports")}
        />

        <FormSelect
          label="Nationality"
          placeholder={"Nationality"}
          id="nationality"
          sx={{
            height: "70px",
            width: "100%",
            borderRadius: "16px",
            background: "#fff",
            boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
            fontSize: "16px",
          }}
          options={[{ value: "", label: "Select your option" }, ...options]}
          register={register("nationality")}
          error={errors.nationality}
        />

        {isSignupWithEmailAndPassword && (
          <>
            <FormInput
              label="Password*"
              error={errors.password}
              id="password"
              placeholder="Password"
              register={register("password")}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />  } 
                </IconButton>
              }
            />

            <FormInput
              label="Re-enter Password*"
              error={errors.passwordConfirm}
              id="passwordConfirm"
              placeholder="Re-enter Password"
              register={register("passwordConfirm")}
              type={showConfirmPassword ? "text" : "password"}
              endAdornment={
                <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />  } 
                </IconButton>
              }            
              />
          </>
        )}

        <FormGroup sx={{ marginTop: "20px" }}>
          <FormControlLabel
            control={<Checkbox {...register("privacy")} />}
            label={
              <Typography
                sx={{
                  color: "#808080",
                  fontWeight: 100,
                  a: {
                    color: "#808080",
                    textDecoration: "none",
                    fontWeight: 700,
                  },
                }}
              >
                I agree to the{" "}
                <Link to={`${company?.footerPagesLinks?.privacy}`} target="_blank" onClick={(e) => {
                  if (!company?.footerPagesLinks?.privacy) {
                    e.preventDefault();
                  }
                }}>
                  Privacy Policy{" "}
                </Link>
                , and the
                <Link to={`${company?.footerPagesLinks?.terms}`} target="_blank"
                  onClick={(e) => {
                    if (!company?.footerPagesLinks?.terms) {
                      e.preventDefault();
                    }
                  }}>
                  {" "}
                  Terms & Conditions.
                </Link>
              </Typography>
            }
          />
          {errors.privacy && (
            <Typography
              sx={{ fontSize: "12px", color: theme.palette.primary.main, marginTop: "8px" }}
            >
              {errors.privacy.message}
            </Typography>
          )}
        </FormGroup>

        {Object.keys(errors).length > 0 && (
          <Typography color={"error"} textAlign={"center"}>
            {`Error in submitting form. Please check and submit again.`}
          </Typography>
        )}

        <div style={{ width: "100%", margin: "0 auto" }}>
          <PrimaryBtn
            isLoading={isLoading}
            type="submit"
            text={"Create Profile"}
          />
        </div>
      </form>
    </>
  );
};

export default FormUI;
