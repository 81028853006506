import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Stack, TextField, Typography, Button, CircularProgress } from "@mui/material";
import Heading from "../../components/shared/Heading";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../services/axiosInstance";
import FormSelect from "../../components/FormCollection/FormSelect";
import { Genders } from "../../components/register/completeProfile/data";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";

// Define Yup schema
const useYupSchema = (bookingRangeStart: Date, bookingRangeEnd:Date) => yup.object().shape({
  name: yup.string().required("Name is required"),
  phoneNumber: yup.string().required("Phone number is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  date: yup.date()
    .min(bookingRangeStart, `Date must be after ${bookingRangeStart}`)
    .max(bookingRangeEnd, `Date must be before ${bookingRangeEnd}`)
    .required("Date is required"),
  numberOfPacks: yup.number().positive("Number of packs must be positive").required("Number of packs is required"),
  gender: yup.string().required("Gender is required"),
  comments: yup.string(),
});

type FormData = {
  name: string;
  phoneNumber: string;
  email: string;
  date: string;
  numberOfPacks: string;
  gender: string;
  comments: string;
};

const BirthdayCelebrationForm = () => {
  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  const bookingRangeStart = company?.bookingRange?.start ? dayjs(company.bookingRange.start, "YYYYMMDD").toDate() : dayjs().toDate();
  const bookingRangeEnd = company?.bookingRange?.end ? dayjs(company.bookingRange.end, "YYYYMMDD").toDate() : dayjs().toDate();

  const validationSchema = useYupSchema(bookingRangeStart!, bookingRangeEnd!);

  const { handleSubmit, register,control, formState: { errors }, reset } = useForm<FormData>({
    resolver: yupResolver(validationSchema)
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('booking/dswBirthdayEnquiry', data);
      toast("Your birthday celebration booking has been successfully submitted!", {
        theme: "colored",
        type: "success",
      });
      reset();
    } catch (error) {
      console.error(error);
      toast("An error occurred while submitting your booking. Please try again.", {
        theme: "colored",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack sx={{ marginTop: 2, gap: 3 }}>
      <Heading heading="Celebrate at Dubai Kids World Enquiry" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ gap: 3 }}>
          {/* Name input field */}
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Name"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          {/* Phone Number input field */}
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Phone Number"
                variant="outlined"
                type="tel"
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber?.message}
              />
            )}
          />
          {/* Email input field */}
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                variant="outlined"
                type="email"
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
          {/* Date input field */}
          <Controller
            name="date"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Date"
                type="date"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                inputProps={{ 
                  min: dayjs(bookingRangeStart).toDate(), 
                  max: dayjs(bookingRangeEnd).toDate() 
                }}
                error={!!errors.date}
                helperText={errors.date?.message}
              />
            )}
          />
          {/* Number of Packs input field */}
          <Controller
            name="numberOfPacks"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Number of People"
                type="number"
                variant="outlined"
                error={!!errors.numberOfPacks}
                helperText={errors.numberOfPacks?.message}
              />
            )}
          />
          {/* Gender selection field using FormSelect */}
          <Controller
            name="gender"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormSelect
                {...field}
                label="Gender"
                placeholder="gender"
                id="gender"
                sx={{
                  height: "70px",
                  width: "100%",
                  borderRadius: "16px",
                  background: "#fff",
                  boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
                  fontSize: "16px",
                }}
                options={Genders}
                register={register("gender")}
                error={errors.gender}
              />
            )}
          />
          {/* Comments input field */}
          <Controller
            name="comments"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Comments"
                variant="outlined"
                multiline
                rows={4}
              />
            )}
          />
          {/* Submit button */}
          <Button type="submit" variant="contained" color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Stack>
      </form>
      <ToastContainer />
    </Stack>
  );
};

export default BirthdayCelebrationForm;
