import { Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import { ISportsContainer } from "./Types";

const SportsTabs: React.FC<ISportsContainer> = ({ data, onChange, value }) => {
  const theme = useTheme();

  return (
    <Tabs
      value={value}
      onChange={onChange}
      variant="scrollable"
      scrollButtons
      allowScrollButtonsMobile
      aria-label="scrollable prevent tabs example"
      sx={{
        width: "100%",
        "& .MuiTabs-flexContainer": {
          gap: "10px",
          justifyContent: data?.length === 1 ? "center" : "",
          paddingTop: "10px",
          paddingBottom: "10px",
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "transparent",
        },
        "& .MuiTabScrollButton-root.Mui-disabled": {
          display: "none",
        },
        boxShadow: "0px 6px 40px 0px rgba(208, 208, 208, 0.2)",
      }}
    >
      {data?.map((sport) => (
        <Tab
          key={sport.value}
          label={sport.label}
          value={sport.value}
          sx={{
            fontSize: "16px !important",
            padding: "6px !important",
            borderRadius: "10px !important",
            // boxShadow: "0px 6px 40px 0px rgba(208, 208, 208, 0.2)",
            height: "36px",
            textTransform: "none",
            fontWeight: 400,
            textDecoration: "none",
            color: "#808080 !important",
            background: "#fff",
            "&.Mui-selected": {
              background: theme.palette.primary.main,
              color: "#fff !important",
            },
          }}
        />
      ))}
    </Tabs>
  );
};

export default SportsTabs;
