import React, { ReactNode, useEffect, useState, useContext } from "react";

import { auth } from "../config/firebase";
import {
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  signOut,
  OAuthProvider,
  User,
  signInWithCredential,
} from "firebase/auth";

import { extractUserInfo } from "../Utils/extract";
import useUser, { UserData } from "../hooks/useUser";
import { toast } from "react-toastify";
import axiosInstance from "../services/axiosInstance";
// @ts-ignore
const { login } = require("webtonative/SocialLogin/google");

export interface AuthProviderProps {
  children?: ReactNode;
}

export interface AuthContextModel {
  auth: Auth;
  user: UserData | undefined;
  isAuthLoading: boolean;
  setUser: (user: UserData) => void;
  signIn: (email: string, password: string) => any;
  signUp: (input: UserData) => any;
  signInWithProvider: (
    provider: GoogleAuthProvider | OAuthProvider,
    isAndroid?: boolean
  ) => any;
  resetPassword: (email: string) => Promise<void>;
  logout: () => void;
  deleteAccount: () => void;
}

export const AuthContext = React.createContext<AuthContextModel>(
  {} as AuthContextModel
);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<UserData | undefined>(undefined);
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(true);
  const { createUser, getUser, deleteUser } = useUser();

  const signUp = async (input: any) => {
    let firebaseUser: User | undefined;
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        input.email,
        input.password!
      );
      firebaseUser = userCredential.user;

      delete input.password;
      delete input.passwordConfirm;
      const createdUser = await createUser(
        {
          ...input,
          id: firebaseUser.uid,
          tenantId: auth.tenantId!,
        },
        true
      );

      return createdUser;
    } catch (error: any) {
      if (firebaseUser) {
        await firebaseUser.delete();
        toast("Please try to sign up again", {
          type: "error",
        });
      }
      return { error };
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const firebaseUser = userCredential.user;
      return await getUser(firebaseUser.uid);
    } catch (error: any) {
      return { error: error };
    }
  };

  const resetPassword = async (email: string) => {
    try {
      await axiosInstance.post(`/users/auth/resetPassword`, {
        email,
        tenantId: auth.tenantId,
      });
      toast("Check your email to reset your password", {
        theme: "colored",
        type: "success",
      });
    } catch {
      toast("Error while reset password");
    }
  };

  const signInWithProvider = async (
    provider: GoogleAuthProvider | OAuthProvider,
    isAndroid: boolean = false
  ) => {
    try {
      if (isAndroid) {
        login({
          callback: async (value: any) => {
            console.log("Received value from Google login:", value);
            if (value.isSuccess) {
              console.log(
                "Google login is successful, processing credentials..."
              );
              const credential = GoogleAuthProvider.credential(value.idToken);
              console.log("credential obtained from idToken:", credential);
              const userCredential = await signInWithCredential(
                auth,
                credential
              );
              console.log("User credential obtained:", userCredential);
              const firebaseUser = userCredential.user;
              console.log("Firebase user:", firebaseUser);
              const userInfo = extractUserInfo(firebaseUser.providerData[0]);
              console.log("Extracted user info:", userInfo);
              const additionalUserInfo = getAdditionalUserInfo(userCredential);
              console.log("Additional user info:", additionalUserInfo);
              if (additionalUserInfo?.isNewUser) {
                console.log("User is new, creating user...");
                return await createUser(
                  {
                    ...userInfo,
                    id: firebaseUser.uid,
                    tenantId: auth.tenantId!,
                    isEmailVerified: true,
                    isProfileCompleted: false,
                  },
                  false
                );
              } else {
                console.log("User exists, fetching user data...");
                return await getUser(firebaseUser.uid);
              }
            } else {
              console.error("Google login failed");
              console.log("Type:", value?.type);
              throw new Error("Google login failed");
            }
          },
        });
      } else {
        const userCredential = await signInWithPopup(auth, provider);
        const firebaseUser = userCredential.user;
        const userInfo = extractUserInfo(firebaseUser.providerData[0]);
        const additionalUserInfo = getAdditionalUserInfo(userCredential);
        if (additionalUserInfo?.isNewUser) {
          return await createUser(
            {
              ...userInfo,
              id: firebaseUser.uid,
              tenantId: auth.tenantId!,
              isEmailVerified: true,
              isProfileCompleted: false,
            },
            false
          );
        } else {
          return await getUser(firebaseUser.uid);
        }
      }
    } catch (error: any) {
      return { error: error.message };
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setUser(undefined);
    } catch (error) {
      toast("Error while logout");
    }
  };

  const deleteAccount = async () => {
    const currentUser = auth.currentUser;
    try {
      await deleteUser(currentUser?.uid!);
      await currentUser?.delete();
      setUser(undefined);
      toast("Account Deleted Successfully", {
        theme: "colored",
        type: "success",
      });
    } catch (error) {
      toast("Error while Delete Account", {
        theme: "colored",
        type: "error",
      });
    }
  };

  useEffect(() => {
    //function that firebase notifies you if a user is set
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      try {
        if (!user) {
          throw new Error();
        } else {
          if (auth.tenantId) {
            const currentUser = await getUser(user.uid);
            setUser(currentUser);
          }
        }
      } catch (error) {
        setUser(undefined);
      }
      setIsAuthLoading(false);
    });
    return unsubscribe;
  }, []);

  const values = {
    signUp,
    user,
    signIn,
    resetPassword,
    auth,
    signInWithProvider,
    setUser,
    isAuthLoading,
    logout,
    deleteAccount,
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
