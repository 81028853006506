import { useContext, useState } from "react";
import ClassSelector from "../../components/Calendar/ClassSelector";
import ClassSessionSelector from "../../components/Calendar/ClassSessionSelector";
import DaySelector from "../../components/Calendar/DaySelector";
import Heading from "../../components/shared/Heading";

import dayjs from "dayjs";

import { useSearchParams } from "react-router-dom";
import useCompany from "../../hooks/useCompany";
import useClassSessions from "../../hooks/useClassSessions";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";

import { FunActivityCalendarWrapper } from "./style";
import ContentLoader from "../../components/shared/ContentLoader";

const FunActivities = () => {
  const { selectedService, company, setQuantity } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;


  const { isLoading: isLoadingSessions, sessions } = useClassSessions(
    company?.id || "",
    selectedService
  );
  // const [selectedPickerDate, setSelectedPickerDate] = useState<Date>(
  //   new Date(dayjs(selectedDay, "DD/MM/YYYY").toISOString())
  // );

  setQuantity(1);

  return (
    
      <FunActivityCalendarWrapper>
        {/* <Heading heading="Fun Activities" sx={{ width: "100%", mt: "25px" }} /> */}
        {/* <DaySelector
          selectedPickerDate={selectedPickerDate}
          setSelectedPickerDate={setSelectedPickerDate}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
        /> */}

        <ClassSelector type="class" subType="fun" />

        <ClassSessionSelector
          sessions={sessions}
          isLoading={isLoadingSessions}
          type="fun"
        />
      </FunActivityCalendarWrapper>
  
  );
};

export default FunActivities;
