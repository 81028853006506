import { Box, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CartItem from "../../components/cart/CartItem";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import { CartWrapper } from "./style";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import CartTotal from "../../components/cart/CartTotal";
import ContentLoader from "../../components/shared/ContentLoader";
import useTitle from "../../hooks/useTitle";
import { getCurrency } from "../../Utils/format";


interface IProps {
  title: string;
}
const Cart: React.FC<IProps> = ({ title }) => {
  useTitle(title);
  const naviagte = useNavigate();
  const { bookingCart, company, isCartLoading } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;


  const getCartTotal = () => {
    let price = bookingCart.reduce((prev, cur) => {
      if (cur.addonPurchases) {
        let addonsPrice: number = cur.addonPurchases.reduce(
          (prev2, cur2) => prev2 + cur2.addon.price * cur2.quantity,
          0
        );

        return prev + addonsPrice + cur.price * cur.quantity;
      } else return prev;
    }, 0);

    return price;
  };

  return (
    <ContentLoader isLoading={isCartLoading}>
      <CartWrapper>
        <Box className="content">
          <Box className="details">
            {bookingCart.length > 0 ? (
              <>
                <Stack sx={{ width: "100%", gap: "14px" }}>
                  {bookingCart.map((booking) => (
                    <CartItem booking={booking} key={booking.id} />
                  ))}
                </Stack>
                <Stack
                  className="details"
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginY: "34px",
                  }}
                >
                  <CartTotal
                    title="Cart Total"
                    value={getCartTotal()}
                    currency={getCurrency(company?.currency)}
                  />
                </Stack>
                <Box className="details">
                  <PrimaryBtn
                    onClick={() => {
                      naviagte("/CartCheckout");
                    }}
                    text="Proceed To Checkout"
                  />
                </Box>
              </>
            ) : (
              <>
                <Typography sx={{ width: "100%", marginY: "24px" }}>
                  Your cart is now empty
                </Typography>
                <Box className="details">
                  <PrimaryBtn
                    onClick={() => {
                      window.open(company?.website || "", "_self")
                    }}
                    text="Back to Home"
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </CartWrapper>
    </ContentLoader>
  );
};

export default Cart;
