import { Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import { IProfileTabs } from "./Types";

const ProfileTabs: React.FC<IProfileTabs> = ({
  activeTab,
  onChangeTabHandler,
}) => {
  const theme = useTheme();
  return (
    <Tabs
      value={activeTab}
      onChange={onChangeTabHandler}
      sx={{
        minHeight: "70px",
        paddingY: "8px",
        paddingX: "11px",
        borderRadius: "16px",
        boxShadow: "0 6px 40px rgba(208, 208, 208, 0.4)",
        marginBottom: "30px",

        "& .MuiTabs-flexContainer": {
          gap: "10px",
          // flexWrap: "wrap",
        },
        "& .MuiTabs-indicator": {
          backgroundColor: "transparent",
        },
      }}
    >
      <Tab
        label={"Profile"}
        value={"profile"}
        sx={{
          fontSize: "14px",
          padding: "14px",
          borderRadius: "12px",
          height: "54px",
          textTransform: "none",
          fontWeight: 500,
          maxWidth: "50px",
          color: "#808080 !important",
          "&.Mui-selected": {
            background: theme.palette.primary.main,
            color: "#fff !important",
            boxShadow: "0 10px 40px #D4D9E8",
          },
        }}
      />
      <Tab
        label={"Upcoming bookings"}
        value={"upcoming"}
        sx={{
          fontSize: "14px",
          padding: "14px",
          borderRadius: "12px",
          height: "54px",
          textTransform: "none",
          fontWeight: 500,
          color: "#808080 !important",
          maxWidth: "50px",
          "&.Mui-selected": {
            background: theme.palette.primary.main,
            color: "#fff !important",
            boxShadow: "0 10px 40px #D4D9E8",
          },
        }}
      />
      <Tab
        label={"Previous bookings"}
        value={"previous"}
        sx={{
          fontSize: "14px",
          padding: "14px",
          borderRadius: "12px",
          height: "54px",
          textTransform: "none",
          fontWeight: 500,
          color: "#808080 !important",
          maxWidth: "50px",

          "&.Mui-selected": {
            background: theme.palette.primary.main,
            color: "#fff !important",
            boxShadow: "0 10px 40px #D4D9E8",
          },
        }}
      />
    </Tabs>
  );
};

export default ProfileTabs;
