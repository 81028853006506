import React from "react";
import { UserProfileDataProps } from "./Types";
import { Box } from "@mui/system";
import QRCode from "react-qr-code";
import { DSW_COMPANY_ID } from "../../Utils/constants";

const UserProfileData: React.FC<UserProfileDataProps> = ({
  firstName,
  lastName,
  email,
  id,
  authToken
}) => {
  return (
    <div
      style={{
        width: "100%",
        padding: "0 25px",
        fontWeight: "500",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "40px",
          marginBottom: "15px",
        }}
      >
        <h4
          style={{
            color: "#171717",
            fontSize: "18px",
            marginRight: "24px",
            width: "100px",
            textAlign: "left",
          }}
        >
          First Name
        </h4>
        <p
          style={{
            color: "#808080",
          }}
        >
          {firstName}
        </p>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "40px",
          marginBottom: "15px",
        }}
      >
        <h4
          style={{
            color: "#171717",
            fontSize: "18px",
            marginRight: "24px",
            marginBottom: "15px",
            width: "100px",
            textAlign: "left",
          }}
        >
          Last Name
        </h4>
        <p
          style={{
            color: "#808080",
          }}
        >
          {lastName}
        </p>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "40px",
          textAlign: "left",
        }}
      >
        <h4
          style={{
            color: "#171717",
            fontSize: "18px",
            marginRight: "24px",
            width: "100px",
          }}
        >
          Email
        </h4>
        <p
          style={{
            color: "#808080",
          }}
        >
          {email}
        </p>
      </Box>
      <Box>
        <QRCode value={`${window.location.origin}/CheckIn?companyId=${DSW_COMPANY_ID}&userId=${id}&authToken=${authToken}`} />
      </Box>
    </div>
  );
};

export default UserProfileData;
