import React, { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useCompany from "../../hooks/useCompany";

import ContentLoader from "../../components/shared/ContentLoader";
import DaySelector from "../../components/Calendar/DaySelector";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";

import useTitle from "../../hooks/useTitle";

import ClassSelector from "../../components/Calendar/ClassSelector";
import useClassSessions from "../../hooks/useClassSessions";
import dayjs from "dayjs";
import { GymCalendarWrapper } from "./style";
import Heading from "../../components/shared/Heading";
import TimeSelector from "../../components/Calendar/TimeSelector";
import useAvailableStartingTimes from "../../hooks/useAvailableStartingTimes";
import useCart from "../../hooks/useCart";
import { Typography } from "@mui/material";

interface IProps {
  title: string;
}

const GymCalendar: React.FC<IProps> = ({ title }) => {
  useTitle(title);

  const {
    selectedService,
    company,
    selectedDay,
    setSelectedDay,
    selectedLocation,
    selectedResource,
    isAllResourcesOptionEnabled,
    availableStartingTimesResource,
    bookingCart,
    setQuantity,
    isCartLoading,
  } = useContext(CheckoutContext) as CheckoutContextProps;

 

 
  const { addToCart } = useCart();

  const [selectedPickerDate, setSelectedPickerDate] = useState<Date>(
    new Date(dayjs(selectedDay, "DD/MM/YYYY").toISOString())
  );

  const { loading: isLoadingStartingTimes } = useAvailableStartingTimes({
    companyId: company?.id,
    branchId: selectedLocation,
    serviceId: selectedService,
    selectedDay,
    selectedResource,
    isAllResourcesOptionEnabled,
    bookingCart,
    selectedPickerDate
  });

  setQuantity(1);
  return (
    
      <GymCalendarWrapper>
        <Heading heading="Book a Gym Session" />
        <ClassSelector type="standard" subType="gym" />

        <DaySelector
          selectedPickerDate={selectedPickerDate}
          setSelectedPickerDate={setSelectedPickerDate}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
        />


        <Typography className="kids-label">
          Gym access lasts for up to 2 hours per session.
        </Typography>

        <TimeSelector
          availableStartingTimes={availableStartingTimesResource!}
          isLoadingStartingTimes={isLoadingStartingTimes || isCartLoading}
          addToCart={addToCart}
          showDurationFilter={false}
          timeUnit="Session"
        />
      </GymCalendarWrapper>
   
  );
};

export default GymCalendar;
