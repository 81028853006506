import { Box, Button, Typography } from "@mui/material";
import { Session } from "../../../hooks/useClassSessions";
import { ClassSessionChipWrapper } from "./style";
import { useContext, useState } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import { useNavigate } from "react-router-dom";
import { formatStringTime, getCurrency } from "../../../Utils/format";
import useCart from "../../../hooks/useCart";
import { toast } from "react-toastify";

interface IProps {
  session: Session;
}
const ClassSessionChip: React.FC<IProps> = ({ session }) => {
  const navigate = useNavigate();
  const { setSelectedSession, setSelectedDuration, setSelectedSlot, selectedService, company, servicesMap, quantity } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const { addToCart } = useCart();

  const [isLoading , setIsLoading] = useState<boolean>(false);


  const handleClick = async () => {
    setSelectedDuration(undefined);
    setSelectedSession(session);
    setIsLoading(true);
    const service = servicesMap?.get(selectedService)
    if (service?.addons && service.addons.length > 0) {
      navigate(`/Addons?companyId=${company?.id}`);
    } else {
      const addedToCart = await addToCart({
        selectedService,
        selectedDuration: undefined,
        selectedSlot: undefined,
        quantity: quantity || 1,
        selectedSession: session,
        addonsList: [],
        childrenInformation: [],
        timezone: company?.timezone
      });

      if (addedToCart) {
        setSelectedSession(undefined);
        setSelectedSlot(undefined);
        navigate(`/CartCheckout?companyId=${company?.id}`);
      } else {
        toast("Error while adding the item to the cart", {
          theme: "colored",
          type: "error",
        });
      }

      setIsLoading(false);
    }



  };

  const service = servicesMap?.get(selectedService);
  return (
    <ClassSessionChipWrapper>
      <Box className="description"><Typography className="header">{`${session.name}`}</Typography></Box>
      <Box className="description">
        <Box>{service?.image && <img src={service?.image} />}</Box>
        {service?.description && <Typography>{service?.description}</Typography>}
      </Box>
      <Box className="details">
        <Typography className="item">
          {formatStringTime(session.startTime)}
        </Typography>
        <Typography className="item">{`${session.duration} Min`}</Typography>
        <Typography className="item">
          {session.price === 0 ? "Free" : `${session.price} ${getCurrency(company?.currency)}`}
        </Typography>
      </Box>
      <Box className="book-button">
        <Button disabled={isLoading} variant="contained" onClick={handleClick}>Book Now</Button>
      </Box>
    </ClassSessionChipWrapper>
  );
};

export default ClassSessionChip;
