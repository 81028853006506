import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../context/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";
import { getUserSchema } from "../../../Utils/validate";

import useUser from "../../../hooks/useUser";
import { useForm } from "react-hook-form";
import FormUI from "./FormUI";
import { ICompleteProfile, IFormTypes } from "./Types";
import { CheckoutContext, CheckoutContextProps } from "../../../context/CheckoutContext";

const CompleteProfile: React.FC<ICompleteProfile> = ({
  isSocialLogin,
  email,
  redirectToLogin,
  redirectToCalendar,
}) => {
  const navigate = useNavigate();
  const { user, setUser, signUp } = useAuth();
  const { updateUser, sendWelcomeEmail } = useUser();
  const completeProfileForm = useForm<IFormTypes>({
    resolver: yupResolver(getUserSchema(!isSocialLogin)),
  });

  const {
    company
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (email) completeProfileForm.setValue("email", email);
  }, [email, completeProfileForm]);

  const onSubmit = async (data: IFormTypes) => {
    setIsLoading(true);
    if (isSocialLogin) {
      try {
        if (!user) throw new Error("You should Login first");

        const response = await updateUser({
          ...user,
          ...data,
          isProfileCompleted: true,
          favoriteSports: data.favoriteSports.filter(val => val !=undefined)
        });
        setUser(response);
        await sendWelcomeEmail(user.id!);
        if (redirectToCalendar) navigate(`/?companyId=${company?.id}`);
      } catch (error: any) {
        completeProfileForm.setError("privacy", { message: error.message });
      }
    } else {
      const response = await signUp({
        ...data,
        isEmailVerified: false,
        isProfileCompleted: true,
        tenantId: "",
      });

      if (response?.error) {
        completeProfileForm.setError("privacy", {
          message: response.error.message,
        });
      } else {
        navigate(`/emailVerification?companyId=${company?.id}&email=${data.email}`);
      }
    }
    setIsLoading(false);
  };

  return (
    <FormUI
      form={completeProfileForm}
      isSignupWithEmailAndPassword={!isSocialLogin}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
};

export default CompleteProfile;
