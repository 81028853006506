import React, { useContext, useEffect } from "react";
import Heading from "../../components/shared/Heading";
import useTitle from "../../hooks/useTitle";
import { Typography } from "@mui/material";
import { useFieldArray, useForm } from "react-hook-form";
import { IFormTypes } from "../../components/KidsBooking/Types";
import FormUI from "../../components/KidsBooking/FormUI";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import useCart from "../../hooks/useCart";
import { useNavigate } from "react-router-dom";

const KidsBooking = () => {
  useTitle("Kids Play Area Booking");

  const navigate = useNavigate();

  const { addToCart } = useCart();

  const {
    quantity,
    company,
    selectedService,
    selectedDuration,
    selectedSlot,
    setSelectedSession,
    setSelectedSlot,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<IFormTypes>();
  const { fields, replace } = useFieldArray({
    control,
    name: "children",
  });

  useEffect(() => {
    const inputsArray = new Array(+(quantity || 0))
      .fill("0")
      .map(() => ({ name: "", age: 0 }));

    if (!fields.length) replace(inputsArray);
  }, [fields.length, replace, quantity]);

  const onSubmitHandler = (data: IFormTypes) => {
    // addToCart({
    //   selectedService,
    //   selectedDuration,
    //   selectedSlot,
    //   quantity: quantity || 1,
    //   selectedSession: undefined,
    //   addonsList: [],
    //   childrenInformation: data.children,
    // });
    // setSelectedSession(undefined);
    // setSelectedSlot(undefined);
    navigate(`/AddOns?companyId=${company?.id}`, {
      state: {
        childrenInformation: data.children,
      },
    });
  };

  return (
    <>
      <Heading heading="Kids Play Area" sx={{ marginTop: "24px" }} />
      
      <Typography
        sx={{
          fontSize: "18px",
          color: "#808080",
          textAlign: "center",
        }}
      >
        For the safety of children, we keep a record of everyone inside the play
        area. Please input the children’s names below.
      </Typography>

      <FormUI
        onSubmitHandler={handleSubmit(onSubmitHandler)}
        register={register}
        errors={errors}
        fields={fields}
      />
    </>
  );
};

export default KidsBooking;
