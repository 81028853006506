import React, { useContext } from "react";
import { SessionRowProps } from "./Type";
import { Box } from "@mui/system";
import { CircularProgress, Stack, Typography } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import useCart from "../../../hooks/useCart";

const SessionRow: React.FC<SessionRowProps> = ({
  booking,
  startTime,
  duration,
  price,
  currency,
  sx,
}) => {
  const { isCartLoading } = useContext(CheckoutContext) as CheckoutContextProps;
  const { removeFromCart } = useCart();

  const handleCancel = () => {
    if (booking) removeFromCart(booking);
  };

  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        color: "#808080",
        flex: 1,
        flexWrap: "wrap",
        height: "max-content",
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
        <Typography className="duration">{startTime}</Typography>
        {duration && <Typography>{duration} Min</Typography>}
      </Box>
      <Typography
        sx={{
          color: "#5182FF",
          fontSize: "12px",
          fontWeight: "bold",
          marginLeft: "auto",
        }}
        className="price"
      >
        {price} {currency}
      </Typography>
      {!!booking && (
        <Box className="cancel-button" sx={{ height: "24px" }}>
          {isCartLoading ? (
            <CircularProgress />
          ) : (
            <DeleteOutlineOutlinedIcon
              onClick={handleCancel}
              sx={{ cursor: "pointer", color: "#808080" }}
            />
          )}
        </Box>
      )}
    </Stack>
  );
};

export default SessionRow;
