import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import Calendar from "./pages/calendar";
import Cancellation from "./pages/cancellation";
import Cart from "./pages/cart";
import AddOns from "./pages/cart/AddOns";
import CartCheckout from "./pages/cart/CartCheckout";
import Checkout from "./pages/checkout";
import Confirmation from "./pages/confirmation";
import PackageCancellation from "./pages/package/packageCancellation";
import PackageConfirmation from "./pages/package/packageConfirmation";
import PackagePurchase from "./pages/package/packagePurchase";
import Status from "./pages/status";
import { AppWrapper } from "./Style";
import "react-toastify/dist/ReactToastify.css";
import Register from "./pages/register";
import { BaseUrlToCompany, RegistrationStep } from "./Utils/constants";
import Home from "./pages/Home";
import Profile from "./pages/profile";
import Packages from "./pages/package/packages";
import MembershipPurchase from "./pages/package/membershipPurchase";
import ClassCalendar from "./pages/class";
import Error from "./pages/Error";
import GymCalendar from "./pages/gym";
import ResetPassword from "./pages/resetPassword";
import EmailVerification from "./pages/emailVerification";
import EmailVerificationConfirmation from "./pages/emailVerification/emailVerificationConfirmation";
import MemberShips from "./pages/memberships";
import Privacy from "./pages/Privacy";
import CancellationPolicy from "./pages/CancellationPolicy";
import Terms from "./pages/Terms";
import Layout from "./components/Layout";
import KidsCalendar from "./pages/kids";
import KidsBooking from "./pages/kids/KidsBooking";
import FunActivities from "./pages/funActivities";
import ConsentForm from "./pages/consentForm";
import CheckIn from "./pages/checkIn";
import { ThemeProvider } from "@mui/material";
import { createCustomTheme } from "./theme";
import { COLORS } from "./Utils/colors";
import useCompany from "./hooks/useCompany";
import ContentLoader from "./components/shared/ContentLoader";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "./context/CheckoutContext";
import useCart from "./hooks/useCart";
import CoachCalendar from "./pages/coach";
import InValidEmailVerification from "./pages/emailVerification/inValidEmailVerification";
import DynamicGTMContainer from "./DynamicGTMContainer";
import MarketplaceCalendar from "./pages/MartketplaceCalendar";
import BirthdayCelebrationForm from "./pages/birthdayCelebrationForm";

const App: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [theme, setTheme] = useState<any>("");

  const [searchParams] = useSearchParams();
  let companyId = searchParams.get("companyId") || "";
  if (!companyId) {
    companyId = BaseUrlToCompany.get(window.location.origin) || "";
  }
  const { loading: isLoadingCompany } = useCompany(companyId);

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  // create a layout component that contains the header
  const toggleDrawer = (open: boolean) => setIsOpen(open);

  useEffect(() => {
    if (company?.id || !companyId) {
      setTheme(createCustomTheme(company?.primaryColor || COLORS.blue));
    }
  }, [company?.id, companyId, company?.primaryColor]);

  useCart(true);

  return (
    <>
      <ThemeProvider theme={theme}>
        <DynamicGTMContainer />
        <AppWrapper>
          <ContentLoader isLoading={isLoadingCompany || !theme}>
            <Layout isOpen={isOpen} toggleDrawer={toggleDrawer}>
              <Routes>
                <Route
                  path="/Calendar"
                  element={<Calendar title="Calendar" />}
                />

                <Route
                  path="/MarketplaceCalendar"
                  element={<MarketplaceCalendar title="MarketplaceCalendar" />}
                />

                <Route
                  path="/Class"
                  element={<ClassCalendar title="Class" />}
                />

                <Route
                  path="/Coach"
                  element={<CoachCalendar title="Coach" />}
                />

                <Route path="/Gym" element={<GymCalendar title="Gym" />} />

                <Route
                  path="/KidsArea"
                  element={<KidsCalendar title="KidsArea" />}
                />

                <Route path="/KidsBooking" element={<KidsBooking />} />

                <Route
                  path="/Checkout"
                  element={<Checkout title="Booking Details" />}
                />
                <Route
                  path="/Confirmation"
                  element={<Confirmation title="Confirmation" />}
                />
                <Route
                  path="/Cancellation"
                  element={<Cancellation title="Cancellation" />}
                />
                <Route path="/Status" element={<Status title="Status" />} />
                <Route
                  path="/PackageConfirmation"
                  element={<PackageConfirmation />}
                />

                <Route path="/PackagePurchase" element={<PackagePurchase />} />
                <Route
                  path="/MembershipPurchase"
                  element={<MembershipPurchase />}
                />
                <Route
                  path="/PackageCancellation"
                  element={<PackageCancellation title="PackageCancellation" />}
                />
                <Route path="/Cart" element={<Cart title="Cart" />} />
                <Route path="/My-profile" element={<Profile />} />
                <Route
                  path="/CartCheckout"
                  element={<CartCheckout title="Checkout" />}
                />
                <Route path="/AddOns" element={<AddOns title="Add-ons" />} />
                <Route
                  path="/Packages"
                  element={<Packages title="Packages" />}
                />
                <Route
                  path="/Register"
                  element={
                    <Register
                      // redirectToCalendar={true}
                      registrationStep={RegistrationStep.SignUp}
                    />
                  }
                />
                <Route path="/" element={<Home />} />

                <Route path="/ResetPassword" element={<ResetPassword />} />

                <Route path="/Fun-activities" element={<FunActivities />} />
                <Route path="/Consent-form" element={<ConsentForm />} />

                <Route
                  path="/birthday-celebration"
                  element={<BirthdayCelebrationForm />}
                />

                <Route
                  path="/EmailVerification"
                  element={<EmailVerification />}
                />

                <Route
                  path="/InValidEmailVerification"
                  element={<InValidEmailVerification />}
                />

                <Route
                  path="/EmailVerificationConfirmation"
                  element={<EmailVerificationConfirmation />}
                />

                <Route path="/memberships" element={<MemberShips />} />
                <Route path="/Privacy" element={<Privacy />} />
                <Route
                  path="/Cancellation-policy"
                  element={<CancellationPolicy />}
                />
                <Route path="/Terms" element={<Terms />} />

                <Route path="/CheckIn" element={<CheckIn />} />

                <Route path="*" element={<Error />} />
              </Routes>
            </Layout>
          </ContentLoader>
        </AppWrapper>
      </ThemeProvider>
    </>
  );
};

export default App;
