import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const CartItemWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // margin: 10px;
  border-radius: 12px;
  border: 1px solid #d1d5db;
  padding: 5px;
  width: 100%;
  flex-basis: 25%;
  flex-grow: 0;

  .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .cancel-button {
    margin-left: 15px;
  }

  &.cartItem {
    margin: 0;
    padding: 0 16px 0 0;
    box-shadow: 0 6px 40px rgba(208, 208, 208, 0.2);
    border: 0;
    border-radius: 16px;
  }
`;

export const CartCheckoutDetailsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  box-shadow: 0px 10px 40px #d4d9e8;
  border-radius: 12px;
  padding: 10px;

  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: 0px 10px 40px #d4d9e8;
    border-radius: 12px;
    padding: 10px;
    margin-top: 10px;
    font-weight: bold;

    .header {
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
`;

export const CartCheckoutItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export const CartCheckoutFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 35px;
`;

export const AddonItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 35px;

  &.item-container {
    border-radius: 16px;
    box-shadow: 0 6px 40px rgba(208, 208, 208, 0.2);
    padding: 6px 12px;
    margin: 0;
    // max-width: 350px;
    position: relative;

    @media screen and (max-width: 767px) {
      img {
        margin-top: 32px
      }
    }
  }
`;
