import dayjs from "dayjs";
import React, { ReactNode } from "react";
import { TimerResult, useTimer } from "react-timer-hook";
import { toast } from "react-toastify";
import {
  AvailableStartingTimesDuration,
  AvailableStartingTimesResource,
  AvailableStartingTimesSlot,
  DayAvailability,
} from "../hooks/useAvailableStartingTimes";

import { Branch, Company, Resource, Service } from "../hooks/useCompany";
import { Booking } from "../hooks/useCart";

import axiosInstance from "../services/axiosInstance";
import { Session } from "../hooks/useClassSessions";


export interface CheckoutContextProps {
  company?: Company;
  setCompany: (company: Company) => void;
  selectedSlot?: AvailableStartingTimesSlot;
  setSelectedSlot: (slot: AvailableStartingTimesSlot | undefined) => void;
  selectedDuration?: AvailableStartingTimesDuration | undefined;
  setSelectedDuration: React.Dispatch<
    React.SetStateAction<AvailableStartingTimesDuration | undefined>
  >;
  selectedLocation: string;
  setSelectedLocation: (location: string) => void;
  selectedService: string;
  setSelectedService: (service: string) => void;
  shouldShowServiceDropdown: boolean;
  setShouldShowServiceDropdown: (value: boolean) => void;
  selectedResource: string;
  setSelectedResource: (resource: string) => void;
  isAllResourcesOptionEnabled: boolean;
  setIsAllResourcesOptionEnabled: (value: boolean) => void;
  availableStartingTimesResource?: AvailableStartingTimesResource;
  setAvailableStartingTimesResource: React.Dispatch<
    React.SetStateAction<AvailableStartingTimesResource | undefined>
  >;
  resourcesMap?: Map<string, Resource>;
  setResourcesMap: (resourcesMap: Map<string, Resource>) => void;
  branchesMap?: Map<string, Branch>;
  setBranchesMap: (branchesMap: Map<string, Branch>) => void;
  servicesMap?: Map<string, Service>;
  setServicesMap: (branchesMap: Map<string, Service>) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
  bookingCart: Booking[];
  setBookingCart: React.Dispatch<React.SetStateAction<Booking[]>>;
  countdownApi: TimerResult;
  selectedDay: string;
  setSelectedDay: React.Dispatch<React.SetStateAction<string>>;
  slotsMap: Map<string, AvailableStartingTimesSlot[]>;
  setSlotsMap: React.Dispatch<React.SetStateAction<Map<string, AvailableStartingTimesSlot[]>>>;
  isCartLoading: boolean;
  setIsCartLoading: React.Dispatch<React.SetStateAction<boolean>>;
  selectedSession: Session | undefined;
  setSelectedSession: React.Dispatch<React.SetStateAction<Session | undefined>>;
  marketplaceName: string | undefined;
  setMarketplaceName: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCurrentRangeAvailability: React.Dispatch<React.SetStateAction<DayAvailability[]>>;
  currentRangeAvailability: DayAvailability[];
}

export const CheckoutContext = React.createContext<CheckoutContextProps | null>(
  null
);

interface Props {
  children?: ReactNode;
}
const CheckoutContextProvider = ({ children }: Props) => {
  const [company, setCompany] = React.useState<Company>();
  const [slotsMap, setSlotsMap] = React.useState<Map<string, AvailableStartingTimesSlot[]>>(
    new Map<string, AvailableStartingTimesSlot[]>()
  );
  const [currentRangeAvailability , setCurrentRangeAvailability] = React.useState<DayAvailability[]>([])
  const [selectedSlot, setSelectedSlot] = React.useState<
    AvailableStartingTimesSlot | undefined
  >();
  const [selectedDuration, setSelectedDuration] =
    React.useState<AvailableStartingTimesDuration>();
  const [selectedLocation, setSelectedLocation] = React.useState<string>("");
  const [selectedService, setSelectedService] = React.useState<string>("");

  const [shouldShowServiceDropdown, setShouldShowServiceDropdown] =
    React.useState<boolean>(false);
  const [selectedResource, setSelectedResource] = React.useState<string>("");
  const [isAllResourcesOptionEnabled, setIsAllResourcesOptionEnabled] =
    React.useState<boolean>(false);
  const [availableStartingTimesResource, setAvailableStartingTimesResource] =
    React.useState<AvailableStartingTimesResource | undefined>();
  const [branchesMap, setBranchesMap] = React.useState<Map<string, Branch>>(
    new Map()
  );
  const [servicesMap, setServicesMap] = React.useState<Map<string, Service>>(
    new Map()
  );
  const [resourcesMap, setResourcesMap] = React.useState<Map<string, Resource>>(
    new Map()
  );

  const [quantity, setQuantity] = React.useState<number>(1);
  const [bookingCart, setBookingCart] = React.useState<Booking[]>([]);
  const [isCartLoading, setIsCartLoading] = React.useState<boolean>(false);
  const countdownApi = useTimer({
    expiryTimestamp: new Date(),
    autoStart: false,
    onExpire: async () => {
      await axiosInstance.post(`/cart/expire`, {
        cartId: bookingCart.length > 0 ? bookingCart[0].cartId : "",
      });
      setBookingCart([]);
      localStorage.removeItem("cart");
      toast("Booking Cart is now Empty", {
        theme: "colored",
        type: "success",
      });
    },
  });

  const [selectedSession, setSelectedSession] = React.useState<Session>();

  const bookingDay = dayjs(company?.bookingRange?.start, "YYYYMMDD");
  const [selectedDay, setSelectedDay] = React.useState<string>(
    dayjs(dayjs().isBefore(bookingDay) ? bookingDay : dayjs()).format(
      "DD/MM/YYYY"
    )
  );

  const [marketplaceName, setMarketplaceName] = React.useState<string | undefined>(undefined);



  return (
    <CheckoutContext.Provider
      value={{
        company,
        setCompany,
        selectedSlot,
        setSelectedSlot,
        selectedDuration,
        setSelectedDuration,
        selectedLocation,
        setSelectedLocation,
        selectedService,
        setSelectedService,
        shouldShowServiceDropdown,
        setShouldShowServiceDropdown,
        selectedResource,
        setSelectedResource,
        isAllResourcesOptionEnabled,
        setIsAllResourcesOptionEnabled,
        availableStartingTimesResource,
        setAvailableStartingTimesResource,
        resourcesMap,
        setResourcesMap,
        branchesMap,
        setBranchesMap,
        servicesMap,
        setServicesMap,
        quantity,
        setQuantity,
        bookingCart,
        setBookingCart,
        countdownApi,
        selectedDay,
        setSelectedDay,
        slotsMap,
        setSlotsMap,
        isCartLoading,
        setIsCartLoading,
        selectedSession,
        setSelectedSession,
        marketplaceName,
        setMarketplaceName,
        currentRangeAvailability,
        setCurrentRangeAvailability
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContextProvider;
