import { Badge, Box, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import React, { useContext } from "react";
import { DayChipWrapper } from "./style";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import {
  AvailableStartingTimesOutput,
  AvailableStartingTimesSlot,
} from "../../../hooks/useAvailableStartingTimes";
import { isAfterCutOff, withinWorkingHours } from "../../../Utils/format";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { isInBookingRange } from "../../../Utils/validate";
interface IProps {
  day: Dayjs;
  setSelectedDay: (day: string) => void;
  selectedDay: string;
  startTime?: string;
  endTime?: string;
}
export const DayChip: React.FC<IProps> = ({
  day,
  selectedDay,
  setSelectedDay,
  startTime,
  endTime,
}) => {
  const handleClick = () => {
    setSelectedDay(day.format("DD/MM/YYYY"));
  };

  const {
    selectedResource,
    currentRangeAvailability,
    servicesMap,
    selectedService,
    company,
  } = useContext(CheckoutContext) as CheckoutContextProps;

  const hasAvailableSlots = () => {
    const service = servicesMap?.get(selectedService);
    const dayAvailability: AvailableStartingTimesOutput =
      currentRangeAvailability?.filter(
        (dayAvailability: any) =>
          dayAvailability.date === day.format("YYYYMMDD")
      )[0]?.availability;
    const durations = dayAvailability?.service.duration || [];
    for (const duration of durations) {
      const slots = duration.slots.filter((slot) =>
        isInBookingRange(company, slot.fullDate)
      );
      for (const slot of slots) {
        if (
          isAfterCutOff(service?.cutoff, slot.fullDate, company?.timezone) &&
          withinWorkingHours(slot.time, startTime, endTime) &&
          (service?.hasOwnProperty("isActive") ? service.isActive : true) &&
          slot.available > 0
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const isSelected = selectedDay === day.format("DD/MM/YYYY");
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <DayChipWrapper
        onClick={handleClick}
        isSelected={isSelected}
        className={`day-chip ${isSelected ? "day-chip--selected" : ""}`}
      >
        <Typography
          sx={{
            marginBottom: "12px",
            fontFamily: "Inter",
            fontSize: "18px !important",
            fontWeight: 500,
            lineHeight: "25.2px",
          }}
        >
          {day.format("ddd")}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "18px !important",
            fontWeight: 500,
            lineHeight: "25.2px",
          }}
        >
          {day.format("D")}
        </Typography>
      </DayChipWrapper>

      {hasAvailableSlots() && (
        <FiberManualRecordIcon
          color="success"
          style={{
            fontSize: "12px",
            marginLeft: "-12px",
            marginTop: "5px",
          }}
        />
      )}
    </Box>
  );
};

export default DayChip;
