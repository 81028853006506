import { object, string, ref, boolean, array, mixed } from "yup";
import { UserData } from "../hooks/useUser";
import { BookingRange } from "./constants";
import dayjs from "dayjs";
import { Company } from "../hooks/useCompany";

export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
};

export const isValidUser = (user: UserData | undefined) => {
  return user && user.isProfileCompleted && user.isEmailVerified
}

export const getUserSchema = (isSignupWithEmailAndPassword: boolean) => {
  const userSchema = object().shape({
    firstName: string().required("First Name is Required"),
    lastName: string().required("Last Name is Required"),
    phoneNumber: string().required("Phone Number is Required"),
    dateOfBirth: string()
      .test(
        "is-nine-years-old",
        "You must be at least 16 years old",
        function (value: string | undefined) {
          if (!value) {
            return false;
          }
          const birthDate = dayjs(value);
          const currentDate = dayjs();
          const age = currentDate.diff(birthDate, "year");
          return age >= 16;
        }
      )
      .required("Date of Birth is Required"),
    whereDidYouHearAboutUs: string().required(
      "How did you hear about us is required"
    ),
    favoriteSports:array()
    .test(
      "defined-elements",
      "Favorite sports is required",
      function (value) {
        if (!Array.isArray(value)) return false;
        const definedElements = value.filter(v => v !== undefined).length;
        return definedElements >= 1;
      }
    ),
    nationality: string(),
    privacy: boolean().oneOf([true], "Privacy is required"),
    gender: string().required("Gender is required")
  });

  if (isSignupWithEmailAndPassword) {
    return userSchema.shape({
      email: string()
        .email("Invalid Email Address")
        .required("Email is Required"),
      password: getPasswordValidation(),
      passwordConfirm: getConfirmPasswordValidation(),
    });
  }

  return userSchema;
};

export const getRegisterationSchema = (isLogin: boolean) => {
  const userSchema = object().shape({
    email: string()
      .email("Invalid Email Address")
      .required("Email is Required"),
  });

  if (isLogin) {
    userSchema.shape({
      password: string()
        .required()
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .matches(
          /[^a-zA-Z0-9]/,
          "Password must contain at least one special character"
        ),
    });
  }

  return userSchema;
};

export const getResetPasswordSchema = () => {
  return object().shape({
    password: getPasswordValidation(),
    passwordConfirm: getConfirmPasswordValidation(),
  });
};

export const getPasswordValidation = () => {
  return string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one number")
    .matches(
      /[^a-zA-Z0-9]/,
      "Password must contain at least one special character"
    );
};

export const getConfirmPasswordValidation = () => {
  return string()
    .required("Password confirmation is required")
    .oneOf([ref("password")], "Passwords don't match");
};

export const isInBookingRange = (company: Company | undefined , date: string) => {
  if(!company?.bookingRange?.start || !company?.bookingRange?.end){
    return true;
  }

  const start = dayjs(company.bookingRange.start, "YYYYMMDD").startOf("day");
  const end =  dayjs(company.bookingRange.end, "YYYYMMDD").endOf("day");
  const dayToCheck = dayjs(date);
  return (
    (dayToCheck.isAfter(start) ||
      dayToCheck.isSame(start)) &&
    (dayToCheck.isBefore(end) ||
      dayToCheck.isSame(end))
  );
};


export const getCountdownTime = (company: Company)=> {
  return (company?.countdownTimeInMinutes || 20 )*60;
}
