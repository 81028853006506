import { useTheme } from "@mui/material";
import React from "react";
import DaySelectorHeader from "./DaySelectorHeader";
import DaysList from "./DaysList";
import { DaySelectorWrapper } from "./style";

interface IProps {
  selectedPickerDate: Date;
  selectedDay: string;
  setSelectedPickerDate: (date: Date) => void;
  setSelectedDay: (day: string) => void;
}
const DaySelector: React.FC<IProps> = ({
  selectedPickerDate,
  selectedDay,
  setSelectedPickerDate,
  setSelectedDay,
}) => {
  const theme = useTheme()
  return (
    <DaySelectorWrapper className="days-wrapper" theme={theme}>
      <DaySelectorHeader
        selectedPickerDate={selectedPickerDate}
        setSelectedPickerDate={setSelectedPickerDate}
        setSelectedDay={setSelectedDay}
      />
      <DaysList
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
        selectedPickerDate={selectedPickerDate}
      />
    </DaySelectorWrapper>
  );
};

export default DaySelector;
