import React, { useContext, useEffect, useState } from "react";
import { Button, Box, CircularProgress } from "@mui/material";
import { ProfileWrapper } from "./style";
import LandingPageTitle from "../../components/shared/LandingPageTitle";
import UserProfileData from "../../components/UserProfileData";
import PackageContainer from "../../components/Home/PackageContainer";
import UserBooking from "../../components/UserBooking";
import ProfileTabs from "../../components/ProfileTabs";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useProfile from "../../hooks/useProfile";
import { CartData, VoucherData } from "../../hooks/useProfile";
import PrimaryBtn from "../../components/FormCollection/PrimaryBtn";
import { useAuth } from "../../context/AuthContext";
import CancelCardModal from "../../components/SessionCard/CancelCardModal";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { formatPrice, getCurrency } from "../../Utils/format";
import { toast } from "react-toastify";
import { CheckoutContext, CheckoutContextProps } from "../../context/CheckoutContext";

interface BookingDetailsProps { }

const Profile: React.FC<BookingDetailsProps> = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState<
    "profile" | "upcoming" | "previous"
  >("profile");
  const [showDeleteAccountDialog, setShowDeleteAccountDialog] =
    React.useState(false);
  const { getUserBookings, getUserVouchers, cancelBooking , getUserToken } = useProfile();
  const [carts, setCarts] = useState<CartData[]>([]);
  const [vouchers, setVouchers] = useState<VoucherData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [authToken , setAuthToken] = useState<string>("");

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;


  const [cancelState, setCancelState] = useState<{
    id: string;
    price: number;
    modal: "before" | "after" | "failure" | "none";
    date: any;
    time: any;
  }>({ id: "", price: 0, modal: "none", date: "", time: "" });

  const { user, deleteAccount } = useAuth();
  const handleDeleteAccountDialog = (value: boolean) => {
    setShowDeleteAccountDialog(value);
  };

  const onChangeTabHandler = (
    _: React.SyntheticEvent<Element, Event>,
    value: "profile" | "upcoming" | "previous"
  ) => {
    setActiveTab(value);
  };

  const getProfileData = async () => {
    setIsLoading(true);
    const bookingData = await getUserBookings();
    const vouchersData = await getUserVouchers();
    const authToken = await getUserToken();
    setAuthToken(authToken);
    setCarts(bookingData);
    setVouchers(vouchersData);
    setIsLoading(false);
  };

  useEffect(() => {
    if(company){
      getProfileData();
    }
  }, [company]);

  const getDate = (date: string, startTime: string) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6);
    const hour = startTime.slice(0, 2);
    const min = startTime.slice(2);
    return dayjs(`${year}/${month}/${day} ${hour}:${min}`);
  };

  const getBookingData = (carts: CartData[], type: "prev" | "upcoming") => {
    const newArr = carts
      .map((cart: CartData) => {
        return cart.bookings
          .filter((booking) =>
            type === "upcoming"
              ? getDate(booking.date, booking.startTime) >= dayjs()
              : getDate(booking.date, booking.startTime) < dayjs()
          )
          .map((booking) => {
            return {
              ...booking,
              addonPurchases: cart.addons.filter(
                (addon) => addon.linkedBookingId === booking.id
              ),
            };
          });
      })
      .flat(2);
    return newArr;
  };

  const cancelBookingHandler = async () => {
    try {
      setIsLoading(true);
      await cancelBooking(cancelState.id);
      setCancelState({ ...cancelState, modal: "after", date: "" });
    } catch (error) {
      setCancelState({ ...cancelState, modal: "failure", date: "" });
      setIsLoading(false);
    }
  };

  const refetchProfile = async () => {
    setIsLoading(true);
    setCancelState({ id: "", price: 0, modal: "none", date: "", time: "" });
    await getProfileData();
    setIsLoading(false);
  };

  const handleDeleteAccount = async () => {
    await deleteAccount();
    handleDeleteAccountDialog(false);
    navigate(`/?companyId=${company?.id}`);
  };

  // console.log("Bookings", carts);
  // console.log("UpcomingBookings", getBookingData(carts, "upcoming"));
  // console.log("PrevBookings", getBookingData(carts, "prev"));

  const getPackages = () => {
    return vouchers
      .filter((item) => item.type === "credit")
      .map((item) => {
        let prices = [];
        if (item.price) {
          prices = [
            { label: "Package Code", value: item.code },
            {
              label: "Package Value",
              value: `${item.totalCredit} ${getCurrency(company?.currency)}`,
            },
            {
              label: "Redeemed Value",
              value: `${item.usedCredit} ${getCurrency(company?.currency)}`,
            },
            {
              label: "Remaining Value",
              value: `${item.totalCredit - item.usedCredit} ${getCurrency(company?.currency)}`,
            },
            {
              id: "price",
              label: "Price of Package",
              value: `${item.price} ${getCurrency(company?.currency)}`,
            },
            {
              label: "Purchase Date",
              value: dayjs(item.createdAt).format("MMM DD, YYYY"),
            },
          ];
        } else {
          prices = [
            { label: "Package Code", value: item.code },

            {
              label: "Remaining Value",
              value: `${item.totalCredit - item.usedCredit} ${getCurrency(company?.currency)}`,
            },
          ];
        }

        return { ...item, prices };
      });
  };

  const getMemberships = () => {
    console.log(vouchers.filter((item) => item.type === "membership"));
    return vouchers
      .filter((item) => item.type === "membership")
      .map((item) => ({
        name: item.name,
        prices: [
          {
            label: "Membership  Code",
            value: item.code,
          },
          { label: "Membership Price", value: formatPrice(item.price, company) },
          {
            label: "Start Date",
            value: dayjs(item.startDate).format("MMM DD, YYYY") || "",
          },
          {
            label: "Valid Until",
            value: dayjs(item.endDate).format("MMM DD, YYYY") || "",
          },
        ],
      }));
  };

  return (
    <ProfileWrapper>
      <Box sx={{ mt: 2 }}>
        <ProfileTabs
          activeTab={activeTab}
          onChangeTabHandler={onChangeTabHandler}
        />
      </Box>

      {activeTab === "profile" && (
        <>
          <LandingPageTitle title={`Profile - ${user?.firstName}`} />
          <Box sx={{ margin: "19px 0", width: "100%" }}>
            <UserProfileData
              firstName={user?.firstName || ""}
              lastName={user?.lastName || ""}
              email={user?.email || ""}
              id={user?.id || ""}
              authToken={authToken}
            />
          </Box>
          <LandingPageTitle title="Packages" />
          {isLoading ? (
            <CircularProgress sx={{ my: 4 }} />
          ) : getPackages().length ? (
            getPackages().map((item) => (
              <PackageContainer
                key={item.code}
                title={item.name || "Package for cancelled Booking"}
                prices={item.prices}
              />
            ))
          ) : (
            <p>No Packages Available</p>
          )}

          <LandingPageTitle title="Memberships" />
          {isLoading ? (
            <CircularProgress sx={{ my: 4 }} />
          ) : getMemberships().length ? (
            getMemberships().map((item) => (
              <PackageContainer title={item.name || ""} prices={item.prices} />
            ))
          ) : (
            <p>No Memberships Available</p>
          )}

          <PrimaryBtn
            text="Delete Account"
            onClick={() => handleDeleteAccountDialog(true)}
            sx={{ marginY: "24px" }}
          />
        </>
      )}

      {activeTab === "upcoming" && (
        <>
          {isLoading ? (
            <CircularProgress />
          ) : getBookingData(carts, "upcoming").length ? (
            <>
              <UserBooking
                title="Upcoming Bookings"
                booking={getBookingData(carts, "upcoming")}
                onCancelBookingHandler={(
                  id: string,
                  date: string,
                  price: number,
                  time: string
                ) =>
                  setCancelState({
                    id,
                    price,
                    modal: "before",
                    date,
                    time,
                  })

                }
                isCancelAddon={false}
                isCancelBooking
              />
              <PrimaryBtn
                sx={{ margin: "20px 0" }}
                text="Add Another Booking"
                href="/"
              />
            </>
          ) : (
            <p>There are no upcoming bookings.</p>
          )}
        </>
      )}

      {activeTab === "previous" && (
        <>
          {isLoading ? (
            <CircularProgress />
          ) : getBookingData(carts, "prev").length ? (
            <>
              <UserBooking
                title="Previous Bookings"
                booking={getBookingData(carts, "prev")}
                isCancelAddon={false}
                isCancelBooking={false}
              />
              <PrimaryBtn
                sx={{ margin: "20px 0" }}
                text="Add Another Booking"
                href="/"
              />
            </>
          ) : (
            <p>There are no Previous bookings.</p>
          )}
        </>
      )}

      <Dialog
        open={showDeleteAccountDialog}
        onClose={() => handleDeleteAccountDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            paddingX: "13px",
            paddingTop: "17px",
            paddingBottom: "9px",
            borderRadius: "12px",
            boxShadow: "0 10px 40px #D4D9E8",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: "#808080",
            textAlign: "center",
            marginBottom: "8px",
            padding: 0,
          }}
        >
          Are you sure you would like to delete you account?
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{
              fontSize: "9px",
              fontWeight: 500,
              color: "#808080",
              textAlign: "center",
              marginBottom: "12px",
            }}
          >
            If you have any bookings they will NOT be refunded.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            onClick={handleDeleteAccount}
            autoFocus
            sx={{
              fontSize: "10px",
              fontWeight: 500,
              textTransform: "none",
              borderRadius: "6px",
              boxShadow: "none",
            }}
          >
            Delete Account
          </Button>
          <Button
            variant="contained"
            onClick={() => handleDeleteAccountDialog(false)}
            sx={{
              fontSize: "10px",
              fontWeight: 500,
              textTransform: "none",
              borderRadius: "6px",
              boxShadow: "none",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <CancelCardModal
        isOpen={cancelState.modal === "before"}
        onClose={() => setCancelState({ ...cancelState, modal: "none" })}
        text="Booking will not be refunded, the value will be added as a package to your account"
        onClick={cancelBookingHandler}
        btnText="Cancel Booking"
      />

      <CancelCardModal
        isOpen={cancelState.modal === "after"}
        onClose={refetchProfile}
        text={`The amount was added to your Dubai Sports World Account as a voucher, please check the packages section under "My Profile".`}
        onClick={refetchProfile}
        btnText="Continue"
      />

      <CancelCardModal
        isOpen={cancelState.modal === "failure"}
        onClose={() => setCancelState({ ...cancelState, modal: "none" })}
        text={
          "Booking cannot be cancelled as booking start date is in less than 72 hours."
        }
        onClick={() => setCancelState({ ...cancelState, modal: "none" })}
        btnText="Continue"
      />
    </ProfileWrapper>
  );
};

export default Profile;
