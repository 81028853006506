import React, { useState, useContext, useEffect } from "react";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../../context/CheckoutContext";
import {
  AppBar,
  Button,
  Badge,
  IconButton,
  Stack,
  Box,
  useMediaQuery,
} from "@mui/material";
import { ShoppingCartOutlined } from "@mui/icons-material";
import Sidebar from "../Sidebar/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu.svg";
import { ReactComponent as ShoppingCartIcon } from "../../../assets/icons/shopping-cart.svg";
import logo from "../../../assets/logos/logo-v2.svg";
import logoMd from "../../../assets/logos/logo-md.svg";

import ControlledCountdown from "../controlledCountdown";
import { ROUTES_WITHOUT_CART_ICON } from "../../../Utils/constants";

const AppTopNav: React.FC = () => {
  const isMd = useMediaQuery("(min-width:600px)");
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const toggleDrawer = (open: boolean) => setIsOpen(open);

  const { bookingCart, company } = useContext(
    CheckoutContext
  ) as CheckoutContextProps;

  const navigateToCart = () => {
    navigate(`/Cart?companyId=${company?.id}`);
  };

  const location = useLocation();
  const showCart = !ROUTES_WITHOUT_CART_ICON.includes(
    location.pathname.toLowerCase()
  );

  return (
    <>
      <AppBar
        position="static"
        sx={{
          boxShadow: "none",
          width: pathname === "/" ? "calc(100% - 40px)" : "100%",
          maxWidth: "768px",
          background: "#fff",
        }}
        className="app-header"
      >
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 0",
            background: "transparent",
            width: "100%",
          }}
        >
          <IconButton
            size="large"
            edge="start"
            color="default"
            aria-label="menu"
            onClick={() => setIsOpen(true)}
          >
            {company?.showSidebar && <MenuIcon />}
          </IconButton>
          <div
            style={{
              textAlign: "center",
              width: "300px",
              cursor: "pointer",
            }}
            onClick={() => window.open(company?.website || "", "_blank")}
          >
            <Box>
              <img
                src={company?.logo}
                alt={`${company?.name} Logo`}
                width={company?.logoDimensions?.width || 120}
                height={company?.logoDimensions?.height || 90}
              />
            </Box>
          </div>

          <Button
            variant="text"
            sx={{
              p: 0,
              minWidth: 0,
              "&:hover": {
                bgcolor: "transparent",
              },
            }}
            onClick={navigateToCart}
          >
            {showCart && (
              <Badge badgeContent={bookingCart?.length} color="primary">
                <ShoppingCartIcon />
              </Badge>
            )}
          </Button>
        </Stack>
      </AppBar>
      {showCart ? (
        <Box>
          <ControlledCountdown />
        </Box>
      ) : null}

      {company?.showSidebar && (
        <Sidebar isOpen={isOpen} toggleDrawer={toggleDrawer} />
      )}
    </>
  );
};

export default AppTopNav;
