import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useAuth } from "../../context/AuthContext";

import Register from "../../pages/register";
import { CheckoutFormWrapper } from "./style";
import { RegistrationStep } from "../../Utils/constants";
import { isValidUser } from "../../Utils/validate";
import TermsAgreementCard from "./TermsAgreementCard";
import { ValidationOutput } from "../../hooks/usePackage";
import {
  CheckoutContext,
  CheckoutContextProps,
} from "../../context/CheckoutContext";
import GuestModeForm from "./GuestModeForm";

interface IProps {
  handleClick: any;
  isLoading: boolean;
  packageValidationOutput?: ValidationOutput;
  totalPrice?: number;
}
const CheckoutForm: React.FC<IProps> = ({
  handleClick,
  isLoading,
  packageValidationOutput,
  totalPrice,
}) => {
  const { user } = useAuth();

  const { company } = useContext(CheckoutContext) as CheckoutContextProps;

  return (
    <CheckoutFormWrapper>
      {!company?.hasAuthentication ? (
        <GuestModeForm handleClick={handleClick} isLoading={isLoading} packageValidationOutput={packageValidationOutput}/>
      ) : isValidUser(user) ? (
        <Box>
          <TermsAgreementCard
            onClick={handleClick}
            isLoading={isLoading}
            packageValidationOutput={packageValidationOutput}
            totalPrice={totalPrice}
          />
        </Box>
      ) : (
        <>
          <Typography>Please Login to checkout</Typography>
          <Register
            registrationStep={RegistrationStep.Login}
            redirectToCalendar={false}
          />
        </>
      )}
    </CheckoutFormWrapper>
  );
};

export default CheckoutForm;
