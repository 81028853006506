import React from "react";
import { LandingPageTitleProps } from "./Types";
import { Box, Stack, Typography } from "@mui/material";

const LandingPageTitle: React.FC<LandingPageTitleProps> = ({ title }) => {
  return (
    <Stack sx={{ flexDirection: "row", width: "100%" }}>
      <Typography
        variant="h2"
        sx={{
          fontSize: "24px",
          color: "#fff",
          bgcolor: "#C82E34",
          paddingX: "24px",
          paddingY: "5px",
          minHeight: "40px",
        }}
      >
        {title}
      </Typography>

      <Box
        sx={{
          bgcolor: "#C82E34",
          width: "40px",
          minHeight: "40px",
          position: "relative",
          left: "-1px",
          clipPath: "polygon(0% 100%, 0% 0%, 100% 100%)",
        }}
      ></Box>
    </Stack>
  );
};

export default LandingPageTitle;
