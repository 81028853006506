import React from "react";
import { Box, Typography } from "@mui/material";
import { PackageInfo } from "./Types";

const PackageContainerRow: React.FC<PackageInfo> = ({ label, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "90%",
        height: "40px",
        textAlign: "left",
      }}
    >
      <Typography
        variant="h4"
        component={"h4"}
        className="muted"
        style={{
          fontSize: "18px",
          marginRight: "24px",
        }}
      >
        {label}
      </Typography>
      <p className="muted">{value}</p>
    </Box>
  );
};

export default PackageContainerRow;
