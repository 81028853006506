import {
  // Box,
  MenuItem,
  Typography,
  Select,
  SelectChangeEvent,
  Stack,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import { AddonItemWrapper } from "./style";

import { Addon } from "../../hooks/useAddons";

interface IProps {
  addon: Addon;
  setSelectedAddonsQuantities: React.Dispatch<
    React.SetStateAction<Map<string, number>>
  >;
}
const AddonItem: React.FC<IProps> = ({
  addon,
  setSelectedAddonsQuantities,
}) => {
  const [value, setValue] = useState<string>("0");
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
    setSelectedAddonsQuantities((oldQuantities) => {
      oldQuantities.set(addon.id, Number(event.target.value));
      return oldQuantities;
    });
  };

  return (
    <AddonItemWrapper className="item-container">
      {/* <Box className="products-section-item"> */}
      <Chip
        label={addon?.isRent ? "Rent" : "Purchase"}
        color="primary"
        variant="outlined"
        sx={{
          position: "absolute",
          left: "4px",
          top: "8px",
          backgroundColor: "#FDE9F1",
          border: "none",
        }}
      />

      <img
        src={addon.image}
        style={{
          width: "auto",
          maxWidth: "100%",
          height: "100px",
          objectFit: "contain",
        }}
        alt="tennis racket"
      />

      <Typography
        sx={{
          fontSize: "13px",
          fontWeight: 500,
          lineHeight: "21px",
          marginRight: "auto",
          marginTop: "8px",
        }}
      >
        {addon.name}
      </Typography>

      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 700,
          lineHeight: "17px",
          color: "#808080",
          marginRight: "auto",
        }}
      >{`${addon.price} ${addon.currency}`}</Typography>

      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          component={"span"}
          sx={{
            fontSize: "10px",
            lineHeight: "17px",
            fontWeight: 100,
            color: "#808080",
          }}
        >
          Add Quantity
        </Typography>
        <Select
          value={value}
          onChange={handleChange}
          sx={{
            border: 0,
            pl: 3,
            py: 2,
            fontSize: "16px",
            fontWeight: "100",
            height: "23px",
            boxShadow: "0 6px 40px rgba(208, 208, 208, 0.2)",
            borderRadius: "8px",

            "&::before": {
              content: "none",
            },
            "& .MuiSelect-select": {
              padding: 0,
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: 0,
            },
          }}
        >
          <MenuItem key="0" value="0">
            0
          </MenuItem>
          {Array.from({ length: addon.maxQuantity }).map((_, index) => (
            <MenuItem key={index} value={`${index + 1}`}>
              {index + 1}
            </MenuItem>
          ))}
        </Select>
      </Stack>
      {/* </Box> */}
    </AddonItemWrapper>
  );
};

export default AddonItem;
