import React from 'react';
import HomeHeader from "../../components/Home/HomeHeader";
import Categories from "../../components/Home/Categories";
import { categories, data } from "../../components/Home/FakeData";
import { useAuth } from "../../context/AuthContext";
import {
  BaseUrlToCompany,
  DSW_COMPANY_ID,
  WINTER_CAMP_COMPANY_ID,
  RegistrationStep,
} from "../../Utils/constants";
import { isValidUser } from "../../Utils/validate";
import ContentLoader from "../../components/shared/ContentLoader";
import Register from "../register";
import MakeBooking from "../../components/Home/MakeBooking";
import { Box, Stack, Typography, useMediaQuery } from "@mui/material";
import Footer from "../../components/shared/footer";
import { useSearchParams } from "react-router-dom";
import instaImg1 from "../../assets/instagram/1-md.jpg";
import instaImg2 from "../../assets/instagram/2-md.jpg";
import instaImg3 from "../../assets/instagram/3-md.jpg";
import { ReactComponent as InstagramIcon } from "../../assets/instagram/icon.svg";
import WinterCampHome from './WinterCampHome';

const instaImages = [instaImg1, instaImg2, instaImg3];

const Home = () => {
  const isMd = useMediaQuery("(min-width:600px)");
  const { user, isAuthLoading } = useAuth();

  const [searchParams] = useSearchParams();
  let companyId = searchParams.get("companyId") || "";
  if (!companyId) {
    companyId = BaseUrlToCompany.get(window.location.origin) || "";
  }

  if (companyId === DSW_COMPANY_ID) {
    return (
      <ContentLoader isLoading={isAuthLoading}>
        <Stack sx={{ width: "100%", overflow: "hidden" }}>
          <HomeHeader />

          {!isValidUser(user) && (
            <>
              <Box
                sx={{
                  width: "100%",
                  paddingX: "24px",
                  paddingY: "50px",
                  paddingBottom: "0px",
                  backgroundColor: "#E0E0E0",
                }}
              >
                <Register
                  registrationStep={RegistrationStep.Login}
                  redirectToCalendar={true}
                />
              </Box>
              <Box
                sx={{
                  height: "100px",
                  width: "100%",
                  paddingX: "24px",
                  paddingY: "50px",
                  backgroundColor: "#E0E0E0",
                  clipPath: "polygon(0px 0px, 100% 0px, 100% 40%, 0px 80%)",
                }}
              ></Box>
            </>
          )}

          <MakeBooking />

          {/* Categories */}
          <Categories data={categories} />

          <Footer />
        </Stack>
      </ContentLoader>
    );
  } else if (companyId === WINTER_CAMP_COMPANY_ID) {
    return <WinterCampHome />; 
  } else {
    return <> </>;
  }
};

export default Home;
