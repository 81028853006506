import React, { useEffect, useState } from "react";
import LandingPageTitle from "../shared/LandingPageTitle";
import { Box } from "@mui/material";
import Pagination from "../Pagination";
import { Booking } from "../../hooks/useCart";
import SessionCard from "../SessionCard";

interface UserBookingProps {
  title: string;
  booking: Booking[];
  onCancelBookingHandler?: (
    id: string,
    date: string,
    price: number,
    time: string
  ) => void;
  isCancelBooking?: boolean;
  isCancelAddon?: boolean;
}

const UserBooking: React.FC<UserBookingProps> = ({
  title,
  booking,
  onCancelBookingHandler,
  isCancelAddon = true,
  isCancelBooking = false,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagedBookings, setPagedBookings] = useState<Booking[]>([]);

  const onNextPageHandler = () => setCurrentPage(currentPage + 1);
  const onPrevPageHandler = () => setCurrentPage(currentPage - 1);

  useEffect(() => {
    setPagedBookings(booking.slice((currentPage - 1) * 5, currentPage * 5));
  }, [currentPage, booking]);

  return (
    <>
      <LandingPageTitle title={title} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
          margin: "40px",
          width: "100%",
        }}
      >
        {pagedBookings.map((item: Booking) => (
          <SessionCard
            key={item.id}
            {...item}
            onCancelBookingHandler={onCancelBookingHandler}
            isCancelAddon={isCancelAddon}
            isCancelBooking={isCancelBooking}
          />
        ))}
        {!!booking.length && (
          <Pagination
            current={currentPage}
            total={booking.length}
            onNext={onNextPageHandler}
            onPrev={onPrevPageHandler}
          />
        )}
      </Box>
    </>
  );
};

export default UserBooking;
